import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaSignOutAlt } from 'react-icons/fa';
import { persistor } from '~/store';
import { signOut as signOutAction } from '~/store/modules/auth/actions';
import logo from '~/assets/img/figo.png';

import { Container } from './styles';

export default function Header() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.user.profile);

  function signOut() {
    persistor.pause();
    dispatch(signOutAction());
  }

  return (
    <Container>
      <div className="brand">
        <a href="/">
          <img src={logo} alt="Figo - Cândido Martins" />
        </a>
      </div>

      <div className="actions">
        <span>{profile.name}</span>
        <span>
          <Link to="/" onClick={signOut}>
            <FaSignOutAlt color="var(--primary)" size={18} />
          </Link>
        </span>
      </div>
    </Container>
  );
}

Header.propTypes = {
  route: PropTypes.string.isRequired,
};
