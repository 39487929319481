import * as Yup from 'yup';

const validation = Yup.object({
  password: Yup.string()
    .min(6, 'A senha deve possuir no mínimo 6 dígitos')
    .required('A senha é obrigatória'),
  confirm_password: Yup.string().when('password', (password, field) =>
    password ? field.required().oneOf([Yup.ref('password')]) : field
  ),
});

export default validation;
