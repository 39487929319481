import React, { useEffect, useState } from 'react';

import TableMilestones from '~/components/TableMileston';

import { Container } from './styles';
import { useSelector } from 'react-redux';
import { store } from '~/store';
import api from '~/services/api';
import moment from 'moment/moment';

function Milestones() {
  const { token } = store.getState().AuthReducer;
  const { projectId } = useSelector(state => state.SocietyReducer);
  const [milestones, setMilestones] = useState([])
  
  useEffect(() => {
    const fetchMilestones = async () => {
      return await api(token).get(`/api/me/projects/${projectId}/milestones`);
    };

    fetchMilestones().then(response => {
      setMilestones(response.data);
    });
  }, [token]);

  function createData(titulo, data, link) {
    return { titulo, data, link };
  }
  
  const rows = milestones.map((milestone, index) =>
    createData(
      milestone.name,
      castDate(milestone.completionDate),
      milestone.id,
    )
  );

  function castDate(dateObj){
    if (!dateObj) {
      return ''
    }

    return new Date(dateObj.join('/')).toLocaleDateString('pt-BR')    
  }

  return (
    <Container>
      <TableMilestones rows={rows} />
    </Container>
  );
}

export default Milestones;
