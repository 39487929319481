import React from 'react';
import PropTypes from 'prop-types';
import { PoseGroup } from 'react-pose';

import { Backdrop, Content } from './styles';

export default function Modal({ name, show, children }) {
  return (
    <PoseGroup>
      {show && (
        <Backdrop key={name}>
          <Content>{children}</Content>
        </Backdrop>
      )}
    </PoseGroup>
  );
}

Modal.propTypes = {
  name: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};
