import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import ModalInfo from '~/components/ModalInfo';
import Input from '~/components/Input';
import InputFormat from '~/components/NumberFormatInput';
import history from '~/services/history';
import classNames from 'classnames';
import { Button } from '@material-ui/core';
import {
  IoIosDocument,
  IoIosEye,
  IoIosTrash,
  IoMdDocument,
} from 'react-icons/io';
import { GiTriquetra } from 'react-icons/gi';
import ConfirmationDialog from '../ConfirmationDialog';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: 'numero',
    numeric: false,
    disablePadding: true,
    label: 'N°',
  },
  {
    id: 'titulo',
    numeric: false,
    disablePadding: true,
    label: 'Título',
  },
  {
    id: 'link',
    numeric: false,
    disablePadding: true,
    label: '',
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          }
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{ color: 'var(--secondary)', fontWeight: 'bold' }}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    padding: 0,
    justifyContent: 'space-between',
    minHeight: 'unset',
    marginBottom: '1rem',
  },
  button: {
    textTransform: 'none',
    fontSize: '15px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  filter: {
    color: 'var(--secondary)',
    textDecoration: 'none',
    textTransform: 'capitalize',
    padding: '0.25rem 0',
    transition: 'all 0.5s',
    borderRadius: '100px',
    backgroundColor: 'transparent',
    '&:hover, &:focus': {
      color: '#5290ea',
    },
  },
  activeFilter: {
    fontWeight: 'bold',
    padding: '0.25rem 0.5rem',
    backgroundColor: 'rgba(83,63,76,.1)',
    transition: 'all 0.5s',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const [show, setShow] = useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [user, setUser] = useState('');
  const { deleteDocuments } = props;
  const { selected } = props;
  const { numSelected } = props;

  return (
    <>
      <ConfirmationDialog
        title="Deseja realmente excluir este Documento?"
        visible={showRemoveDialog && selected.length > 0}
        onConfirm={() => {
          deleteDocuments(selected)
          setShowRemoveDialog(false)
        }}
        onCancel={() => setShowRemoveDialog(false)}
      />

      <ModalInfo
        name="modal-confirm"
        show={show}
        cancel={() => {
          setShow(false);
        }}
        title="CADASTRE UM DOCUMENTO">
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            marginTop: '20px',
          }}>
          <Input label="Nome" name="nome" style={{ marginRight: '10px' }} />
          <div style={{ width: '40%', paddingLeft: '10px' }}>
            <label style={{ margin: '0' }}>Tipo</label>
            <select
              name="tipo"
              id="tipo"
              style={{
                border: '1px solid var(--secondary)',
                borderRadius: '5px',
                height: '40px',
                margin: '5px 0 5px 0',
                cursor: 'pointer',
                fontSize: '14px',
                width: '100%',
                paddingLeft: '5px',
              }}>
              <option value="Fisica" style={{ color: '#000' }}>
                Pessoa Física
              </option>
              <option value="Juridica" style={{ color: '#000' }}>
                Pessoa Júridica
              </option>
            </select>
          </div>
        </div>
        <div
          style={{
            // display: 'flex',
            // width: '100%',
            justifyContent: 'center',
            marginTop: '20px',
          }}>
          <InputFormat
            isFormattedValue
            name="cpf"
            format="###.###.##-##"
            label="CPF"
          />
        </div>
        <Button style={{ margin: 'auto', marginTop: '35px' }}>Cadastrar</Button>
      </ModalInfo>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div">
          <span>
            <b>Documentos</b>
          </span>
        </Typography>

        <div className={classes.actions}>
          <Button
            color="primary"
            startIcon={<IoIosDocument />}
            className={classes.button}
            variant="text"
            onClick={() => history.push('/newDocument')}>
            Novo Documento
          </Button>

          <Button
            color="primary"
            startIcon={<IoIosTrash />}
            className={classes.button}
            disabled={numSelected === 0}
            variant="text"
            onClick={() => setShowRemoveDialog(true)}>
            Excluir
          </Button>
        </div>
      </Toolbar>
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  projeto: PropTypes.string.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    margin: 0,
  },
  table: {
    margin: 0,
    minWidth: 100,
    borderCollapse: 'separate',
  },
  row: {
    padding: '10px 14px',
    height: '50px',
    border: 'none',
    borderRadius: '10px',
  },
  oddRow: {
    backgroundColor: 'rgb(250,247,250)',
    '&:hover, &:focus': {
      backgroundColor: '#ffffff',
    },
  },
  cell: {
    border: 'none',
    fontSize: '14px',
    '&:first-child': {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
    },
    '&:last-child': {
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
      textAlign: 'right',
    },
  },
  visuallyHidden: {
    margin: 0,
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  title: {
    fontWeight: 'bold',
    fontSize: '17px',
  },
}));

export default function EnhancedTable(data) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const rows = data.rows;
  const { deleteDocuments } = data;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    setSelected([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteDocuments]);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    console.log(name)
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    console.log(newSelected)

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.includes(name);

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        projeto={data.projeto.id}
        selected={selected}
        deleteDocuments={deleteDocuments}
      />
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
          aria-label="enhanced table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    className={classNames(
                      classes.row,
                      index % 2 === 0 ? classes.oddRow : undefined
                    )}>
                    <TableCell padding="checkbox" className={classes.cell}>
                      <Checkbox
                        checked={isItemSelected}
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      className={classes.cell}>
                      {row.numero}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      className={classes.cell}>
                      {row.titulo}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      className={classes.cell}>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={() => history.push('/documents/' + row.id)}>
                        <IoIosEye />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
