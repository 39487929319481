import styled from 'styled-components';

export const Container = styled.div`
  width: 80%;
  margin: auto;
  margin-top: 50px;
  background-color: #fff;
  box-shadow: 2px 5px 15px 1px rgba(0, 0, 0, 0.2);
  padding: 20px;
  .group {
    display: flex;
    width: 100%;
  }

  .dataInput {
    .form {
      display: flex;
      justify-content: center;
      flex-direction: column;
      .title {
        font-size: 24px;
      }
      .head {
        margin-top: 25px;
        display: flex;
        justify-content: space-between;
        width: 100%;
        span {
          min-width: 20%;
        }
      }
    }
    .division2 {
      background-color: var(--secondary);
      width: 100%;
      height: 10px;
      border-radius: 50px;
      margin: 20px 0;
    }
    .body {
      > div {
        padding: 15px;
      }
    }
    div {
      margin: auto;
      /* margin-bottom: 15px; */
    }
    span {
      font-weight: bold;
      margin: auto;
    }
  }
  .name {
    margin-bottom: 15px;
    h1 {
      font-weight: bold;
      font-size: 32px;
      margin-left: 35px;
    }
  }
  .division {
    margin-bottom: 15px;
    background-color: var(--secondary);
    width: 100%;
    height: 15px;
    border-radius: 50px;
  }
  .buttons {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    button {
      margin: 5px;
    }
  }
`;
export const Button = styled.button`
  background-color: var(--secondary);
  border: none;
  border-radius: 4px;
  color: #fff;
  min-width: 64px;
  font-size: 16px;
  padding: 6px 16px;
  text-transform: uppercase;
  transition: all 0.2s linear;
  &:hover {
    box-shadow: 2px 5px 15px 1px rgba(0, 0, 0, 0.3);
  }
`;
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  padding-top: 30px;
  > .group {
    flex-direction: row;
    align-items: center;
    height: 20px;
    label {
      margin-left: 5px;
    }
    input {
      margin-top: 0;
    }
  }
  label {
    color: var(--secondary);
    font-weight: bold;
    font-size: 16px;
  }
  input {
    background-color: #ffffff;
    border: 1px solid var(--primary);
    border-radius: 10px;
    color: #3b3c40;
    height: 40px;
    line-height: 16px;
    margin: 5px 0;
    transition: all 0.2s ease;
    &:hover {
      transform: translateY(-3px);
    }
  }
`;
export const ButtonBack = styled.button`
  background-color: #fff;
  border: none;
  border-radius: 4px;
  color: var(--secondary);
  min-width: 64px;
  font-size: 16px;
  padding: 6px 16px;
  text-transform: uppercase;
  transition: all 0.2s linear;
  &:hover {
    box-shadow: 2px 5px 15px 1px rgba(0, 0, 0, 0.3);
  }
`;
