import styled from 'styled-components';

export const Box = styled.div`
  .titleBar {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 2rem 2rem 1.5rem 2rem;
    gap: 1rem;
    background-color: rgba(245,242,245, 0.2);

    .titleBar-breadcrumb {
      flex: 1 1 100%;
    }

    .titleBar-back {
      flex: 0 0 auto;
      margin-top: -0.3rem;
    }

    .titleBar-title {
      flex: 0 0 auto;

      p {
        margin: 0;
        padding: 0;
      }

      h1 {
        font-size: 28px;
        font-weight: bold;
      }
    }

    .titleBar-actions {
      flex: 1 1 auto;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 1rem;
    }

    .titleBar-actions {
      .MuiTab-root {
        min-width: unset !important;
      }
    }
  }
`;
