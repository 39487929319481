import styled from 'styled-components';

export const Container = styled.div`
  width: 75%;
  margin: auto;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  .groupTop {
    background-color: #fff;
    padding: 20px;
  }
  .groupBottom {
    margin-top: 25px;
    background-color: #fff;
    padding: 20px;
  }

  .head {
    margin-bottom: 15px;
    .name {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .info {
        display: flex;
        align-items: center;
        svg {
          margin-left: 10px;
          margin-top: -5px;
          &:hover {
            cursor: pointer;
          }
        }
      }
      h1 {
        font-weight: bold;
        font-size: 32px;
        margin-left: 35px;
      }
      svg {
        margin-right: 35px;
      }
    }
  }
  .cards {
    display: flex;
    justify-content: center;
  }
  .division {
    margin-bottom: 15px;
    background-color: var(--secondary);
    width: 100%;
    height: 15px;
    border-radius: 50px;
  }
  button {
    padding: 10px;
    outline-style: none;
    border: none;
    background: none;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    transition: all 0.2s linear;
    &:hover {
      transform: translateY(-5px);
    }
  }
`;
export const Card = styled.div`
  display: flex;
  width: 45%;
  height: 200px;
  background-color: var(--primary);
  padding: 15px;
  margin: 20px;
  flex-direction: column;
  color: #fff;
  transition: all 0.2s linear;
  box-shadow: 2px 5px 15px 1px rgba(0, 0, 0, 0.2);
  &:hover {
    box-shadow: 2px 5px 15px 1px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }
  .initial {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      font-weight: bold;
      font-size: 26px;
      margin-bottom: 5px;
    }
  }
  .value {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 15px;
  }
  .bottom {
    span {
      font-size: 18px;
      margin-bottom: 10px;
    }
    p {
      font-size: 18px;
      margin-bottom: 10px;
    }
  }
`;
export const Description = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin-right: auto;
  padding: 0 40px 40px 40px;
  label {
    font-size: 18px;
    text-align: left;
    color: var(--secondary);
  }
`;
