import { takeLatest, all, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { useDispatch } from 'react-redux';
import api from '~/services/api';

import SessionService from '~/services/session';
import TokenService from '~/services/token';
import history from '~/services/history';

import { signInSuccess, signFailure } from './actions';
import { start, stop } from '../loader/actions';

import AuthTypes from './types';

export function* signIn({ payload }) {
  try {
    const { email, password } = payload;
    const response = yield call(SessionService.store, {
      username: email,
      password,
    });
    const tokenInitial = response.data.access_token;
    const { data } = yield call(TokenService.store, { tokenInitial });
    if (data) {
      const tokens = data.access_token.split('.');
      const user = JSON.parse(atob(tokens[1]));
      const mountedUser = {
        email: user.email,
        family_name: user.family_name,
        given_name: user.given_name,
        name: user.name,
        email_verified: user.email_verified,
        id: user.sub,
      };
      yield put(signInSuccess(data.access_token, mountedUser));
    }
    history.push('/society');
    toast.success('Seja bem-vindo à Figo!');
  } catch (e) {
    yield put(signFailure());
    toast.error('Login ou Senha incorretos, tente novamente!');
  }
}
export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}
export function signOut() {
  history.push('/');
}

export default all([
  // takeLatest('persist/REHYDRATE', setToken),
  takeLatest(AuthTypes.SIGN_IN_REQUEST, signIn),
  takeLatest(AuthTypes.SIGN_OUT, signOut),
]);
