import api from './apiToken';

const qs = require('qs');

const store = token => {
  const body = {
    grant_type: 'urn:ietf:params:oauth:grant-type:uma-ticket',
    audience: 'figo-backend',
  };
  return api.post(
    `/realms/figo/protocol/openid-connect/token`,
    qs.stringify(body),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        Authorization: `Bearer ${token.tokenInitial}`,
      },
    }
  );
};

export default { store };
