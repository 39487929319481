import React from 'react';
import PropTypes from 'prop-types';
import ModalInfo from '../ModalInfo';
import { Button } from '@material-ui/core';

export default function ConfirmationDialog({ title, visible, onConfirm, onCancel }) {
  return (
    <ModalInfo
      name="modal-delete"
      show={visible}
      cancel={onCancel}
      title={title}>
      <div style={{ display: 'flex', width: '100%', alignItems: 'center', gap: '1rem' }} >
        <Button
          variant="contained"
          disableElevation
          color='secondary'
          style={{ flex: '1 1 auto' }}
          onClick={onConfirm}>
          Confirmar
        </Button>

        <Button
          variant="outlined"
          color='secondary'
          style={{ flex: '1 1 auto' }}
          onClick={onCancel}>
          Cancelar
        </Button>
      </div>
    </ModalInfo>
  );
}

ConfirmationDialog.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
}