import React from 'react';
import PropTypes from 'prop-types';
import { FaTimes as CloseIcon } from 'react-icons/fa';
import Modal from '~/components/Modal';
import { ModalHeader, ModalBody } from './styles';
import { IconButton } from '@material-ui/core';

export default function ModalInfo({
  show,
  cancel,
  title,
  description,
  children,
}) {
  return (
    <Modal name="modal-info" show={show}>
      <ModalHeader>
        <span>{title}</span>

        <IconButton onClick={cancel}>
          <CloseIcon size={20} />
        </IconButton>
      </ModalHeader>

      <ModalBody>
        {children || description}
      </ModalBody>
    </Modal>
  );
}

ModalInfo.defaultProps = {
  title: '',
  description: '',
  children: null,
};

ModalInfo.propTypes = {
  show: PropTypes.bool.isRequired,
  cancel: PropTypes.func.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node,
};
