import React, { useState } from 'react';
import Animated from '~/components/Animated';
import Stepper from '~/components/Stepper';
import history from '~/services/history';
import DatePicker from '~/components/DatePicker';
import TablePartner from '~/components/TablePartner';
import TableClass from '~/components/TableClass';
import TableStock from '~/components/TableStock';
import InputNumber from '~/components/NumberFormatInput';

import { Container, Button, ButtonBack } from './styles';

function Data() {
  return <DatePicker name="data" label="Selecione a data" width={30} />;
}
function createData(name) {
  return { name };
}
const rows = [
  createData('Cupcake'),
  createData('Donut'),
  createData('Eclair'),
  createData('Frozen yoghurt'),
  createData('Gingerbread'),
  createData('Honeycomb'),
  createData('Ice cream sandwich'),
];
function createData2(
  subscritor,
  totalcs,
  integralcs,
  integral,
  reserva,
  reservatotal,
  reservaintegral
) {
  return {
    subscritor,
    totalcs,
    integralcs,
    integral,
    reserva,
    reservatotal,
    reservaintegral,
  };
}
const rows2 = [
  createData2(
    'Cupcake',
    'R$ 20,00',
    'R$ 20,00',
    'R$ 20,00',
    'R$ 20,00',
    'R$ 20,00',
    'R$ 20,00'
  ),
  createData2(
    'Cupcake',
    'R$ 20,00',
    'R$ 20,00',
    'R$ 20,00',
    'R$ 20,00',
    'R$ 20,00',
    'R$ 20,00'
  ),
  createData2(
    'Cupcake',
    'R$ 20,00',
    'R$ 20,00',
    'R$ 20,00',
    'R$ 20,00',
    'R$ 20,00',
    'R$ 20,00'
  ),
  createData2(
    'Cupcake',
    'R$ 20,00',
    'R$ 20,00',
    'R$ 20,00',
    'R$ 20,00',
    'R$ 20,00',
    'R$ 20,00'
  ),
  createData2(
    'Total',
    'R$ 20,00',
    'R$ 30,00',
    'R$ 20,00',
    'R$ 20,00',
    'R$ 20,00',
    'R$ 20,00'
  ),
];
function Partner() {
  return (
    <div className="body">
      <TablePartner rows={rows} />
      <TableClass rows={rows} />
    </div>
  );
}
function Value() {
  return (
    <>
      <div className="group">
        <div>
          <InputNumber
            name="total"
            label="Capital social total"
            defaultValue="0"
          />
          <InputNumber
            name="integralizado"
            label="Capital social integralizado"
            defaultValue="0"
          />
        </div>
        <div>
          <InputNumber name="reserva" label="Reserva total" defaultValue="0" />
          <InputNumber
            name="reservaIntegralizada"
            label="Reserva integralizada"
            defaultValue="0"
          />
        </div>
      </div>
      <div className="division2" />
      <div className="form">
        <span className="title">Ações e valores</span>
        <div className="head">
          <span>Sócio</span>
          <span>Valor total no CS</span>
          <span>Valor integralizado no CS</span>
          <span>Reserva total</span>
          <span>Reserva integralizada</span>
        </div>
        <div className="table">
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    </>
  );
}
function Resume() {
  return <TableStock rows={rows2} />;
}

function Snapshot() {
  const [step, setStep] = useState(0);

  function getStepContent(mainStep) {
    switch (mainStep) {
      case 0:
        return <Data />;
      case 1:
        return <Partner />;
      case 2:
        return <Value />;
      case 3:
        return <Resume />;
      default:
        return 'Unknown step';
    }
  }

  return (
    <>
      <Animated name="fadeIn" delay={200}>
        <Container>
          <div className="head">
            <div className="name">
              <h1>IGOR AMBONATTI</h1>
            </div>
          </div>
          <div className="division" />
          <Stepper stepMain={step} />
          <div className="dataInput">{getStepContent(step)}</div>
          <div className="buttons">
            <ButtonBack
              onClick={() => {
                if (step > 0) {
                  return setStep(step - 1);
                }
                return history.push('/societies');
              }}
            >
              VOLTAR
            </ButtonBack>
            <>
              {step === 3 ? (
                <Button
                  onClick={() => {
                    setStep(step + 1);
                  }}
                >
                  CONCLUIR
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setStep(step + 1);
                  }}
                >
                  AVANÇAR
                </Button>
              )}
            </>
          </div>
        </Container>
      </Animated>
    </>
  );
}

export default Snapshot;
