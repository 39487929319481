import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 300px;
  flex-direction: column;
  justify-content: flex-start;
  background-color: rgb(245,242,245);
  height: 100vh;
  @media (max-width: 900px) {
    width: 100%;
    min-height: 0;
  }

  button {
    margin: 30px auto auto;
  }

  .options {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 3rem 3rem 0 3rem;

    .navigable {
      padding: 0;
      margin-bottom: 1rem;
      color: var(--secondary);
      font-size: 18px;

      a {
        transition: all 0.2s linear;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          color: var(--secondary);
          font-size: 16px;
          font-weight: bold;
        }
      }

      .group {
        margin-right: auto;
        align-items: center;

        svg {
          margin-right: 1rem;
        }
      }

      .open {
        transform: rotate(90deg);
        transition: 0.3s linear;
      }

      .close {
        transition: 0.3s linear;
      }

      .list {
        transition: all 300ms ease;
        display: flex;
        flex-direction: column;

        a {
          color: var(--primary);
          font-size: 16px;
          margin: 0.5rem 0 0.5rem calc(16px + 1rem);
        }

        a:first-child {
          margin-top: 1rem;
        }

        a:last-child {
          margin-bottom: 1rem;
        }
      }
    }

    .Head,
    .top,
    .middle,
    .bottom,
    .foot {
      display: flex;
      flex-direction: column;

      .open {
        transform: rotate(90deg);
        transition: 0.3s linear;
      }

      .close {
        transition: 0.3s linear;
      }

      .group {
        margin-right: auto;
        align-items: center;

        svg {
          margin-top: -5px;
        }
      }

      a {
        border-radius: 5px;
        padding: 15px;
        transition: all 0.2s linear;
        // margin-bottom: 8px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:hover {
          // box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.1);
        }

        span {
          color: var(--secondary);
          font-size: 20px;
          // font-style: italic;
          // font-weight: bold;
          margin-left: 15px;
        }
      }
    }

    .Head {
      border-bottom: 1px solid var(--gray);
      // margin: 25px 0 25px 0;
      // padding: 25px 0;
    }

    .bottom {
      border-bottom: 1px solid var(--gray);
      // padding: 25px 0;

      .list {
        transition: all 300ms ease;
        display: flex;
        flex-direction: column;

        a {
          color: var(--primary);
          font-size: 16px;
          padding: 5px 0 5px 60px;
        }
      }
    }

    .middle {
      border-top: 1px solid var(--gray);
      border-bottom: 1px solid var(--gray);
      // padding: 25px 0;
      // margin: 25px 0 0 0;
    }

    .foot {
      // margin: 25px 0;
    }
  }
`;
