/* eslint-disable no-inner-declarations */
import React from 'react';

import { Form } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import Animated from '~/components/Animated';
import { Container, Section, Content, ButtonGroup } from './styles';
import EnhancedTableHead from '../../../components/TableSocietyRead';
import history from '~/services/history';
import api from '~/services/api';
import Input from '~/components/Input';
import { store } from '~/store';
import { Button } from '@material-ui/core';
import TitleBar from '~/components/TitleBar';

function NewProject() {
  const { token } = store.getState().AuthReducer;

  async function submitSociety(data) {
    try {
      await api(token).post('/api/me/projects', data);
      toast.success('Projeto criado com sucesso!');
      history.push('/projects');
    } catch (e) {
      toast.error('Aconteceu algo, tente novamente!');
    }
  }

  async function mountRequest(data) {
    const mountPayload = {
      name: data?.name,
      summary: data?.description,
    };
    
    submitSociety(mountPayload);
  }

  return (
    <>
      <Animated name="fadeIn" delay={200}>
        <TitleBar
          title="Novo Projeto"
          canBack
        />

        <Container>
          <Form onSubmit={mountRequest}>
            <Content>
              <Section>
                <label>Nome</label>
                <Input
                  isFormattedValue
                  name="name"
                  required
                />
              </Section>
              
              <Section>
                <label>Descrição</label>
                <Input
                  isFormattedValue
                  name="description"
                  required
                />
              </Section>

              <ButtonGroup>
                <Button
                  type='submit'
                  variant="contained"
                  disableElevation
                  color="secondary">
                  Salvar
                </Button>

                <Button
                  variant="outlined"
                  color="secondary"
                  onClick={() => history.goBack(-1)}>
                  Cancelar
                </Button>
              </ButtonGroup>
            </Content>
          </Form>
        </Container>
      </Animated>
    </>
  );
}

export default NewProject;
