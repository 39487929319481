import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useField } from '@rocketseat/unform';

import { Container, Label, Input } from './styles';

export default function NumberFormatInput({
  name,
  format,
  isCurrency,
  label,
  width,
  widthType,
  placeholderAlign,
  disabled,
  isFormattedValue,
  onChange,
  ...rest
}) {
  const ref = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    if (ref.current) {
      registerField({
        name: fieldName,
        ref: ref.current,
        path: 'dataset.currency',
      });
    }
  }, [ref.current, fieldName]); // eslint-disable-line

  function handleChange(e) {
    onChange(e);
    setValue(isFormattedValue ? e.formattedValue : e.floatValue);
  }

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <Input
        id={fieldName}
        name={fieldName}
        format={format}
        getInputRef={ref}
        onValueChange={handleChange}
        data-currency={value}
        mask="_"
        thousandSeparator="."
        decimalSeparator=","
        fixedDecimalScale={isCurrency}
        decimalScale={isCurrency ? 2 : 0}
        allowNegative={false}
        defaultValue={Number(value)}
        width={width}
        widthtype={widthType}
        align={placeholderAlign}
        disabled={disabled}
        prefix="R$ "
        {...rest}
      />
      {error && <span>{error}</span>}
    </Container>
  );
}

NumberFormatInput.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  isFormattedValue: PropTypes.bool,
  isCurrency: PropTypes.bool,
  format: PropTypes.string,
  width: PropTypes.number,
  widthType: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

NumberFormatInput.defaultProps = {
  label: '',
  isFormattedValue: false,
  isCurrency: false,
  format: null,
  width: 100,
  widthType: '%',
  disabled: false,
  onChange: () => {},
};
