/* eslint-disable no-inner-declarations */
import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import Animated from '~/components/Animated';
import InputFormat from '~/components/NumberFormatInput';
import Input from '~/components/Input';
import history from '~/services/history';
import api from '~/services/api';
import { NewSocietyValidation } from '~/validations';
import { store } from '~/store';
import { start, stop } from '~/store/modules/loader/actions';
import { Container, Section, Content, Adress } from './styles';

function EditSociety() {
  const dispatch = useDispatch();
  const { token } = store.getState().AuthReducer;
  const { societyId } = store.getState().SocietyReducer;
  const baseURL = 'https://viacep.com.br/ws';
  const apiCep = axios.create({ baseURL });

  const [society, setSociety] = useState();
  const [cepI, setCepI] = useState();
  const [logradouroI, setLogradouroI] = useState();
  const [cidadeI, setCidadeI] = useState();
  const [bairroI, setBairroI] = useState();
  const [estadoI, setEstadoI] = useState();
  const [type, setType] = useState();

  useEffect(() => {
    async function loadPage() {
      try {
        const response = await api(token).get(`/api/societies/${societyId}`);
        setSociety(response.data);
        setType(response.data.societyType)
      } catch (e) {
        console.error(e);
      }
    }
    loadPage();
  }, [dispatch, societyId, token]);

  async function submitSociety(data) {
    dispatch(start());
    try {
      await api(token).request(`/api/societies/${societyId}`, data, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      history.push('/societies');
      toast.success('Sociedade editada com sucesso!');
    } catch (e) {
      toast.error('Houve algum problema, tente novamente!');
    } finally {
      dispatch(stop());
    }
  }

  async function mountRequest(data) {
    const mountPayload = {
      id: society.id,
      societyType: type,
      name: data.name,
      identification: data.cnpj,
      address: data.logradouro,
      number: data.numero,
      complement: data.complemento,
      neighborhood: data.bairro,
      cep: data.cep,
      city: data.cidade,
      state: data.estado,
    };

    submitSociety(mountPayload);
  }

  if (cepI?.length === 8) {
    async function loadCep() {
      const { data } = await apiCep.get(`/${cepI}/json`);
      setLogradouroI(data.logradouro);
      setCidadeI(data.localidade);
      setBairroI(data.bairro);
      setEstadoI(data.uf);
    }
    loadCep();
  }

  const initialData = {
    id: society?.id,
    name: society?.name,
    cnpj: society?.identification,
    tipo: society?.societyType,
    cep: society?.cep,
    logradouro: logradouroI || society?.address,
    cidade: cidadeI || society?.city,
    bairro: bairroI || society?.neighborhood,
    estado: estadoI || society?.state,
    numero: society?.number,
    complemento: society?.complement,
  };
  return (
    <>
      <Animated name="fadeIn" delay={200}>
        <Container>
          <Form
            onSubmit={mountRequest}
            schema={NewSocietyValidation}
            initialData={initialData}>
            <Content>
              <Section>
                <label>Nome</label>
                <Input
                  name="name"
                // width={100}
                // widthType="%"
                />
              </Section>
              <Section>
                <label>Tipo</label>
                <select
                  name="tipo"
                  id="programs"
                  value={type}
                  onChange={e => {
                    setType(e.target.value);
                  }}>
                  <option value="LTDA">LTDA</option>
                  <option value="SA">SA</option>
                </select>
              </Section>
              <Section>
                <label>CNPJ</label>
                <Input name="cnpj" format="##.###.###/####-##" />
              </Section>
              <Section>
                <label>CEP</label>
                <Input
                  name="cep"
                  format="##.###.###/####-##"
                  onChange={e => {
                    setCepI(e.target.value);
                  }}
                />
              </Section>
              <Adress>
                <div>
                  <label>Logradouro</label>
                  <Input name="logradouro" defaultValue={logradouroI} />
                </div>
                <div>
                  <label>Número</label>
                  <Input name="numero" />
                </div>
              </Adress>
              <Adress>
                <div>
                  <label>Bairro</label>
                  <Input name="bairro" />
                </div>
                <div>
                  <label>Compl.</label>
                  <Input name="complemento" />
                </div>
              </Adress>
              <Adress>
                <div>
                  <label>Cidade</label>
                  <Input name="cidade" />
                </div>
                <div>
                  <label>Estado (sigla)</label>
                  <Input name="estado" />
                </div>
              </Adress>
            </Content>
            <button type="submit">Salvar</button>
          </Form>
          <button
            type="button"
            className="back"
            onClick={() => {
              history.push('/societies');
            }}>
            Voltar
          </button>
        </Container>
      </Animated>
    </>
  );
}

export default EditSociety;

EditSociety.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};
