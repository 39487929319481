import styled from 'styled-components';

export const Summary = styled.div`
  width: 100%;
  padding: 2rem;
  background-color: rgb(250, 247, 250);
  margin-bottom: 2rem;
  border-radius: 10px;
  border: 1px solid rgb(240, 237, 240);

  p {
    padding: 0;
    font-size: 16px;
  }
`;

export const Container = styled.div`
  margin: auto;
  padding: 1.5rem 2.5rem 2.5rem 3rem;
  display: flex;
  flex-direction: column;

  > button {
    padding: 10px;
    outline-style: none;
    border: none;
    background: none;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    margin-top: 10px;
    transition: all 0.2s linear;
    &:hover {
      transform: translateY(-5px);
    }
  }
`;
