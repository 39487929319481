import DashboardTypes from './types';

export function getNumberCarsRequest() {
  return {
    type: DashboardTypes.GET_NUMBERS_CARS_REQUEST,
  };
}

export function getNumberCarsSuccess(numbers) {
  return {
    type: DashboardTypes.GET_NUMBERS_CARS_SUCCESS,
    payload: { numbers },
  };
}
