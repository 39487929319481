import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  margin: auto;
  margin-top: 2rem;

  form {
    width: 100%;
    display: flex;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;

  > .group {
    flex-direction: column;
  }

  input {
    background-color: #ffffff;
    border: 1px solid var(--primary);
    border-radius: 10px;
    color: #3b3c40;
    padding: 0.5 1rem;
    transition: all 0.2s ease;
  }

  label {
    color: var(--secondary);
    font-size: 16px;
  }
  p {
    color: #eee9e9;
    text-align: justify;
  }
  > select {
    border: 1px solid var(--secondary);
    color: #010c16;
    font-weight: bold;
    padding: 0.5 1rem;
    transition: all 0.2s linear;
    &:hover {
      cursor: pointer;
    }

    option {
      background: #eee9e9;
      border-radius: 5px;
      color: #010c16;
      font-weight: bold;
    }
  }
`;
export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
`;

export const Adress = styled.div`
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  div {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-right: 5px;
    input {
      background-color: #ffffff;
      border: 1px solid var(--primary);
      border-radius: 10px;
      color: #3b3c40;
      padding: 0.5 1rem;
      transition: all 0.2s ease;
    }

    label {
      color: var(--secondary);
      font-size: 16px;
    }
    select {
      border: 1px solid var(--secondary);
      color: #010c16;
      font-weight: bold;
      padding: 0.5 1rem;
      transition: all 0.2s linear;
      &:hover {
        cursor: pointer;
      }
    }
  }
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin: auto;
  margin-top: 1rem;
  width: 100%;

  > * {
    flex: 1 1 auto;
  }
`;
