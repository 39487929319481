import styled from 'styled-components';

export const CalendarContainer = styled.div`
  .fc-daygrid-day-frame {
    background-color: #ffebf8;
    border-radius: 10px;
  }

  .fc td,
  .fc th {
    padding: 5px;
  }

  .fc-theme-standard .fc-scrollgrid,
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: none;
  }

  .fc-col-header-cell-cushion {
    text-transform: capitalize;
  }

  .fc .fc-daygrid-day-number {
    padding: 0.5rem 1rem;
    font-weight: 600;
  }

  .fc .fc-toolbar {
    gap: 1rem;
  }

  .fc .fc-toolbar .fc-toolbar-chunk:nth-of-type(2) {
    margin-left: 0;
    margin-right: auto;
  }

  .fc-toolbar-title {
    font-size: 18px;
    text-transform: capitalize;
  }

  .fc .fc-button-group {
    gap: 0.5rem;
  }

  .fc .fc-button-group .fc-button {
    background-color: var(--primary);
    border-radius: 5px;
    border: none;
  }
`;
