import * as Yup from 'yup';

const validation = Yup.object({
  title: Yup.string().required('O Titulo é obrigatório'),
  numberDocument: Yup.string().required('O Numero do documento é obrigatório'),
  doc: Yup.string(),
  pdf: Yup.string(),
});

export default validation;
