import { produce } from 'immer';

import UserTypes from './types';
import AuthTypes from '~/store/modules/auth/types';

const INITIAL_STATE = {
  profile: null,
};

export default function reducer(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case UserTypes.UPDATE_PROFILE_SUCCESS:
        draft.profile = action.payload.profile;
        break;
      case AuthTypes.SIGN_IN_SUCCESS:
        draft.profile = action.payload.user;
        break;
      case AuthTypes.SIGN_OUT:
        draft.profile = null;
        break;
      default:
    }
  });
}
