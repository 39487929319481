import React from 'react';
import PropTypes from 'prop-types';

import { Container } from './styles';

export default function FadeIn({
  children,
  name,
  nameOut,
  delay,
  delayOut,
  duration,
  durationOut,
  isVisible,
}) {
  return (
    <Container
      isVisible={isVisible}
      className="animated"
      animationName={name}
      animationNameOut={nameOut}
      delay={delay}
      delayOut={delayOut}
      duration={duration}
      durationOut={durationOut}
    >
      {children}
    </Container>
  );
}

FadeIn.defaultProps = {
  delay: 0,
  delayOut: 0,
  duration: 1000,
  durationOut: 1000,
  isVisible: true,
  nameOut: '',
};

FadeIn.propTypes = {
  children: PropTypes.element.isRequired,
  name: PropTypes.string.isRequired,
  nameOut: PropTypes.string,
  delay: PropTypes.number,
  delayOut: PropTypes.number,
  duration: PropTypes.number,
  durationOut: PropTypes.number,
  isVisible: PropTypes.bool,
};
