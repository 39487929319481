import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { Button } from '@material-ui/core';
import ModalInfo from '~/components/ModalInfo';
import history from '~/services/history';
import { CalendarContainer } from '~/pages/Projects/Calendar/styles';
import api from '~/services/api';
import { store } from '~/store';

function Calendar({ origin }) {
  const { token } = store.getState().AuthReducer;
  const [showTaskDialog, setShowTaskDialog] = useState(false);
  const [events, setEvents] = useState([]);
  const [event, setEvent] = useState();

  console.log(origin)

  const fetchTasks = async () => {
    return await api(token).get(origin);
  };

  useEffect(() => {
    fetchTasks().then(response => {
      const tasks = response.data
      const calendarTasks = tasks.map(t => ({
        start: new Date(t.anchorDate).toJSON(),
        end: new Date(t.expectedDate).toJSON(),
        title: t.name,
        description: t.longDescription,
        data: '',
        backgroundColor: '#f50057',
        id: t.id
      }))

      setEvents(calendarTasks)
    })
  }, [token]);

  function handleEventView(e) {
    setEvent(events.find(evt => evt.id === e.event.id))
    setShowTaskDialog(true)
  }

  return (
    <CalendarContainer>
      <ModalInfo
        name="modal-alerts"
        show={showTaskDialog}
        cancel={() => {
          setShowTaskDialog(false);
        }}
        title={event?.title}>
        <div style={{ width: '100%', textAlign: 'left' }}>
          <p>
            <strong>Criada em:</strong>
            {new Date(event?.start).toLocaleDateString('pt-BR')}
          </p>
          <p>
            <strong>Previsão de Conclusão:</strong>
            {new Date(event?.end).toLocaleDateString('pt-BR')}
          </p>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginTop: '2rem',
            alignItems: 'center',
            gap: '1rem',
          }}>
          <Button
            variant="contained"
            disableElevation
            color="secondary"
            style={{ flex: '1 1 auto' }}
            onClick={() => history.push('/tasks/' + event.id)}>
            Ir para Tarefa
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            style={{ flex: '1 1 auto' }}
            onClick={() => {
              setShowTaskDialog(false);
            }}>
            Cancelar
          </Button>
        </div>
      </ModalInfo>

      <FullCalendar
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        buttonText={{
          today: 'Hoje',
          month: 'Mensal',
          week: 'Semanal',
          day: 'Diário',
          list: 'Listagem',
        }}
        initialView="dayGridMonth"
        editable
        selectable
        selectMirror
        dayMaxEvents
        events={events}
        locale="pt-br"
        nowIndicator
        eventClick={evt => handleEventView(evt)}
      />
    </CalendarContainer>
  );
}

export default Calendar;
Calendar.propTypes = {
  origin: PropTypes.string,
};
