import styled from 'styled-components';

export const Toolbar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 2rem;

  .actions {
    flex: 0 0 auto;
    display: inline-flex;
    align-items: center;
    vertical-align: center;
  }
`;

export const Container = styled.div`
  width: 100%;
  justify-content: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  padding: 1rem;

  button {
    padding: 10px;
    outline-style: none;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    transition: all 0.2s linear;
    &:hover {
      transform: translateY(-5px);
    }
  }
`;
