import React, { useEffect, useState } from 'react';
import Animated from '~/components/Animated';
import { store } from '~/store';
import api from '~/services/api';
import EnhancedTableHead from '~/components/TableProjects';
import { Container } from '~/pages/Projects/styles';

function Table() {
  const { token } = store.getState().AuthReducer;
  const [projects, setProjects] = useState([]);

  function createData(nome, acesso, status, vermais, index) {
    return { nome, acesso, status, vermais, index };
  }

  useEffect(() => {
    const fetchProjects = async () => {
      return await api(token).get('/api/me/projects');
    };

    fetchProjects().then(response => {
      setProjects(response.data.content);
    });
  }, [token]);

  const rows = projects.map((project, index) =>
    createData(
      project.name,
      project.lastUserAccess,
      project.status,
      project.id,
      index
    )
  );

  return (
    <Animated name="table-projects">
      <Container>
        <EnhancedTableHead rows={rows} />
      </Container>
    </Animated>
  );
}

export default Table;
