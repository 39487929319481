import React, { useEffect, useState } from 'react';

import { Button, Chip, Divider, Grid } from '@material-ui/core';
import {
  IoIosDoneAll,
  IoIosNotifications,
  IoIosPeople,
} from 'react-icons/io';
import { Container, Content } from './styles';
import Animated from '~/components/Animated';
import history from '~/services/history';
import TitleBar from '~/components/TitleBar';
import ConfirmationDialog from '~/components/ConfirmationDialog';
import ModalInfo from '~/components/ModalInfo';
import { store } from '~/store';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import api from '~/services/api';
import { useSelector } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { start, stop } from '~/store/modules/loader/actions';
import { requestProjectDetails } from '~/store/modules/project/actions';
import { useDispatch } from 'react-redux';
import Input from '~/components/Input';
import { Form } from '@rocketseat/unform';
import DatePicker from '~/components/DatePicker';
import { castDate } from '~/utils/parsers';

function TaskDetail() {
  const dispatch = useDispatch();
  const { token } = store.getState().AuthReducer;
  const { project } = store.getState().ProjectReducer;
  const [task, setTask] = useState({})
  const { id } = useParams()
  const { participants } = useSelector(state => state.ProjectReducer.project);

  const [showSupervisorsDialog, setShowSupervisorsDialog] = useState(false);
  const [showRemoveSupervisorDialog, setShowRemoveSupervisorDialog] = useState(
    false
  );
  const [showAlertsDialog, setShowAlertsDialog] = useState(false);
  const [showAddAlertsDialog, setShowAddAlertsDialog] = useState(false);
  const [showCloseTaskDialog, setShowCloseTaskDialog] = useState(false);

  const [selectedParticipants, setSelectedParticipants] = useState([]);
  const [participantOptions, setParticipantOptions] = useState([]);
  const [selectedParticipant, setSelectedParticipant] = useState();
  const [selectedCloseParticipant, setSelectedCloseParticipant] = useState();
  const [alerts, setAlerts] = useState([]);

  const fetchTasks = async () => {
    return await api(token).get(`/api/me/projects/${project.id}/tasks`);
  };

  const fetchAlerts = async (taskId) => {
    return await api(token).get(`/api/me/projects/${project.id}/tasks/${taskId}/alerts`);
  };

  const associateParticipant = async (participantId) => {
    dispatch(start());
    try {
      const url = `/api/me/projects/${project.id}/tasks/${id}/addParticipant/${participantId}`
      await api(token).post(url, {}, { method: 'POST' });
      toast.success('Participante associado com sucesso!');
      fetch()
    } catch (e) {
      toast.error('Aconteceu algum problema, tente novamente!');
    } finally {
      dispatch(stop());
    }
  }

  const desassociateParticipant = async (participantId) => {
    dispatch(start());
    try {
      const url = `/api/me/projects/${project.id}/tasks/${id}/removeParticipant/${participantId}`
      await api(token).delete(url);
      toast.success('Participante associado com sucesso!');
      fetch()
    } catch (e) {
      toast.error('Aconteceu algum problema, tente novamente!');
    } finally {
      dispatch(stop());
    }

    return false
  }

  const addAlert = async (data) => {
    dispatch(start());
    try {
      await api(token).post(`/api/me/projects/${project.id}/tasks/${id}/alerts`, data);
      toast.success('Alerta criado com sucesso!');
      fetch()
    } catch (e) {
      toast.error('Aconteceu algum problema, tente novamente!');
    } finally {
      dispatch(stop());
    }
  }

  const deleteAlert = async (id) => {
    dispatch(start());
    try {
      await api(token).delete(`/api/me/projects/${project.id}/tasks/${task.id}/alerts/${id}`);
      toast.success('Alerta removido com sucesso!');
      fetch()
    } catch (e) {
      toast.error('Aconteceu algum problema, tente novamente!');
    } finally {
      dispatch(stop());
    }
  }

  const closeTask = async (data) => {
    dispatch(start());
    try {
      await api(token).post(`/api/me/projects/${project.id}/tasks/${id}/completeTask`, data);
      toast.success('Tarefa concluída com sucesso!');
      fetch()
    } catch (e) {
      toast.error('Aconteceu algum problema, tente novamente!');
    } finally {
      dispatch(stop());
    }
  }

  const fetch = async () => fetchTasks().then(response => {
    const tasks = response.data;
    const task = tasks.find(task => task.id === id)
    if (!task) {
      history.go(-1)
    }

    setTask(task)

    fetchAlerts(task.id).then(response => {
      const _alerts = response.data?.map(a => {
        const dateTo = new Date(...a.scheduledDateTime);
        const dateNow = new Date();
        const dateBetween = Math.round(
          (dateTo.getTime() - dateNow.getTime()) / (1000 * 3600 * 24)
        );

        a.scheduledDateTime = dateTo.toLocaleString('pt-BR');
        a.dateBetween = dateBetween

        return a;
      }).sort(function (a, b) {
        return a.dateBetween - b.dateBetween;
      });

      setAlerts(_alerts)
    })

    const taskParticipants = task.owners.map(o => o.id)

    setParticipantOptions(
      participants
        .filter(p => !taskParticipants.includes(p.id))
        .map(p => ({
          label: `${p.name} | ${p.email}`,
          value: p.id
        })
        ))
  });

  useEffect(() => {
    fetch()
  }, [token]);

  async function handleAssociateParticipant() {
    if (!selectedParticipants) {
      return
    }

    selectedParticipants.map(item => associateParticipant(item.value))
    return false
  }

  function handleDesassociateParticipant(id) {
    setSelectedParticipant(id)
    setShowRemoveSupervisorDialog(true)
  }

  function confirmDesassociateParticipant() {
    desassociateParticipant(selectedParticipant)
    setShowRemoveSupervisorDialog(false)
    return false
  }

  function handleAddAlert(data) {
    addAlert(data)
    setShowAddAlertsDialog(false)
    return false
  }

  function handleCloseTask(data) {
    closeTask({
      comments: data.comments,
      completionDate: new Date(data.completionDate).toISOString(),
      participantId: selectedCloseParticipant.value
    })
    setShowCloseTaskDialog(false)
    return false
  }

  return (
    <>
      <ConfirmationDialog
        title="Deseja realmente desvincular esse participante?"
        visible={showRemoveSupervisorDialog}
        onConfirm={confirmDesassociateParticipant}
        onCancel={() => {
          setShowRemoveSupervisorDialog(false);
        }}
      />

      <ModalInfo
        name="modal-supervisors"
        show={showSupervisorsDialog}
        cancel={() => {
          setShowSupervisorsDialog(false);
        }}
        title="Adicionar Participantes">
        <Form onSubmit={handleAssociateParticipant} style={{ width: '100%' }}>
          <Select
            name="cnpj"
            color="secondary"
            options={participantOptions}
            placeholder="Selecione Participantes"
            value={selectedParticipants}
            onChange={setSelectedParticipants}
            isSearchable
            isMulti
          />

          <div
            style={{
              display: 'flex',
              width: '100%',
              marginTop: '2rem',
              alignItems: 'center',
              gap: '1rem',
            }}>
            <Button
              type="submit"
              variant="contained"
              disableElevation
              color="secondary"
              style={{ flex: '1 1 auto' }}>
              Salvar
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              style={{ flex: '1 1 auto' }}
              onClick={() => {
                setShowSupervisorsDialog(false);
              }}>
              Cancelar
            </Button>
          </div>
        </Form>
      </ModalInfo>

      <ModalInfo
        name="modal-alerts"
        show={showAlertsDialog}
        cancel={() => {
          setShowAlertsDialog(false);
        }}
        title="Alertas">
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
          }}>
          <div style={{ display: 'flex', gap: '0.5rem', flexWrap: 'wrap' }}>
            {alerts.map(a => (
              <Chip
                label={`${a.scheduledDateTime} (${a.dateBetween}d)`}
                onDelete={() => deleteAlert(a.id)}
              />
            ))}
          </div>

          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              justifyContent: 'left',
              gap: '1rem',
            }}>
            <Button
              onClick={() => setShowAddAlertsDialog(true)}
              variant="contained"
              disableElevation
              color="secondary"
              style={{ flex: '1 1 auto', margin: 0 }}>
              Adicionar Alerta
            </Button>
          </div>
        </div>
      </ModalInfo>

      <ModalInfo
        name="modal-alerts"
        show={showAddAlertsDialog}
        cancel={() => {
          setShowAddAlertsDialog(false);
        }}
        title="Adicionar Alertas">
        <Form onSubmit={handleAddAlert} style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <Input
            required
            label="Dias antecendentes"
            placeholder="Ex.: Notificar [10] dias antes do prazo esperado."
            name="daysBefore"
            type="number"
          />

          <Input
            required
            label="Horas e Minutos antecendentes"
            placeholder="Ex.: Notificar 10h30 antes do prazo esperado."
            name="time"
            type="time"
          />

          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              gap: '1rem',
            }}>
            <Button
              type="submit"
              variant="contained"
              disableElevation
              color="secondary"
              style={{ flex: '1 1 auto' }}>
              Salvar
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              style={{ flex: '1 1 auto' }}
              onClick={() => {
                setShowAddAlertsDialog(false);
              }}>
              Cancelar
            </Button>
          </div>
        </Form>
      </ModalInfo>

      <ModalInfo
        name="modal-close-task"
        show={showCloseTaskDialog}
        cancel={() => {
          setShowCloseTaskDialog(false);
        }}
        title="Finalizar Tarefa">
        <Form onSubmit={handleCloseTask} style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: '1rem' }}>
          <Select
            label="Responsável"
            name="participant"
            color="secondary"
            options={participants.map(p => ({ label: p.name, value: p.id }))}
            placeholder="Selecione o responsável"
            value={selectedCloseParticipant}
            onChange={setSelectedCloseParticipant}
            isSearchable
          />

          <DatePicker
            required
            name="completionDate"
            label="Data de Conclusão"
          />

          <Input
            label="Comentário(Opcional)"
            placeholder="Opcional."
            name="comments"
            type="text"
          />

          <div
            style={{
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              gap: '1rem',
            }}>
            <Button
              type="submit"
              variant="contained"
              disableElevation
              color="secondary"
              style={{ flex: '1 1 auto' }}>
              Salvar
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              style={{ flex: '1 1 auto' }}
              onClick={() => {
                setShowCloseTaskDialog(false);
              }}>
              Cancelar
            </Button>
          </div>
        </Form>
      </ModalInfo>

      <Animated name="fadeIn" delay={200}>
        <TitleBar
          title="Tarefas"
          subtitle={project.name}
          href="/projectDetail#Tarefa"
          canBack
          actions={
            <>
              <Button
                startIcon={<IoIosPeople />}
                variant="text"
                color="primary"
                style={{
                  flex: '0 0 auto',
                  fontWeight: 'normal',
                  textTransform: 'none',
                }}
                onClick={() => setShowSupervisorsDialog(true)}>
                Adicionar Participantes
              </Button>

              <Button
                startIcon={<IoIosNotifications />}
                variant="text"
                color="primary"
                style={{
                  flex: '0 0 auto',
                  fontWeight: 'normal',
                  textTransform: 'none',
                }}
                onClick={() => setShowAlertsDialog(true)}>
                Adicionar Alerta
              </Button>

              {(task && !task.completionDate) && (
                <Button
                  startIcon={<IoIosDoneAll />}
                  variant="text"
                  color="primary"
                  onClick={() => setShowCloseTaskDialog(true)}
                  style={{
                    flex: '0 0 auto',
                    fontWeight: 'normal',
                    textTransform: 'none',
                  }}>
                  Finalizar
                </Button>
              )}
            </>
          }
        />

        <Container>
          <Content>
            <h3 style={{ marginBottom: '3rem' }}>{task.name}</h3>

            <Grid container spacing={2}>
              <Grid item xs={2}>
                <p><strong>Data Limite:</strong></p>
                <p>{castDate(task.expectedDate)}</p>
              </Grid>
              <Grid item xs={3}>
                <p><strong>Data de Conclusão:</strong></p>
                <p>{castDate(task.completionDate)}</p>
              </Grid>
              <Grid item xs={7}>
                <p><strong>Participantes:</strong></p>
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                  {task.owners?.map(o => (
                    <Chip label={o.name} onDelete={() => handleDesassociateParticipant(o.id)} />
                  ))}
                </div>
              </Grid>
            </Grid>

            <Divider style={{ margin: '1rem 0' }} />

            <Grid container spacing={2}>
              <Grid item xs={6}>
                <p><strong>Descrição:</strong></p>
                <p>{task.longDescription}</p>
              </Grid>
              <Grid item xs={6}>
                <p><strong>Observações:</strong></p>
                <p>{task.comments}</p>
              </Grid>
            </Grid>

          </Content>
        </Container>
      </Animated>
    </>
  );
}

export default TaskDetail;
