import styled from 'styled-components';

export const Container = styled.div`
  width: 95%;
  margin: auto;

  .head {
    padding: 10px;
    margin-top: 30px;
    border-radius: 5px;
    background-color: #fff;
    margin-bottom: 25px;

    h1 {
      font-size: 22px;
      color: var(--secondary);
      font-weight: bold;
      margin-bottom: 25px;
    }
  }
  .info {
    display: flex;
    padding: 10px;
    margin-bottom: 15px;
    background-color: #fff;

    div {
      width: 100%;
      margin-left: 5%;
    }
    .descricao {
      flex-direction: column;
    }
  }
  .day {
    display: flex;
    a {
      color: var(--secondary);
    }
    .numero {
      font-size: 16px;
    }
  }
  .back {
    outline-style: none;
    border: none;
    background: none;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    transition: all 0.2s linear;
    color: #fff;
    &:hover {
      transform: translateY(-5px);
    }
  }
  .foot {
    display: flex;
    justify-content: center;
  }
`;
export const Content = styled.div`
  padding: 1rem 2.5rem;
  margin: auto;

  h1 {
    margin-bottom: 1rem;
  }
`;
