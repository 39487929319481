import styled from 'styled-components';
import { lighten } from 'polished';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* border: 1px solid #ff2; */
  padding: 40px;
  background: #fff;
  img {
    margin-bottom: 10vh;
  }

  form {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 1rem;

    button {
      flex: 1 1 auto;
      width: 100%;
      margin-top: 1rem;
    }
  }

  a {
    font-size: 18px;

    &.forgot {
      color: #3b3c40;
      font-weight: bold;
      margin-bottom: 40px;
      transition: color 0.2s ease-in;
      font-size: 14px;

      &:hover {
        color: ${lighten(0.25, '#3b3c40')};
      }
    }

    &.register {
      color: #ffffff;
      background-color: #3b3c40;
      border-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      transition: all 0.2s ease-in;
      width: 200px;
      margin-bottom: 5px;
      svg {
        margin-right: 10px;
      }

      &:hover {
        box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.15);
        transform: translateY(-2px);
      }
    }
  }
`;

export const Logo = styled.img`
  width: 300px;
`;
