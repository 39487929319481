import { createGlobalStyle } from 'styled-components';

import BarlowRegular from '~/assets/font/Barlow-Regular.ttf';
import BarlowItalic from '~/assets/font/Barlow-Italic.ttf';
import BarlowMedium from '~/assets/font/Barlow-Medium.ttf';
import BarlowMediumItalic from '~/assets/font/Barlow-MediumItalic.ttf';
import BarlowBold from '~/assets/font/Barlow-Bold.ttf';
import BarlowBoldItalic from '~/assets/font/Barlow-BoldItalic.ttf';

export default createGlobalStyle`
  @font-face {
    font-family: 'Barlow';
    src: url(${BarlowRegular});
    font-style: normal;
    font-weight: normal;
  }

  @font-face {
    font-family: 'Barlow';
    src: url(${BarlowItalic});
    font-style: italic;
    font-weight: normal;
  }

  @font-face {
    font-family: 'Barlow';
    src: url(${BarlowMedium});
    font-style: normal;
    font-weight: bold;
  }

  @font-face {
    font-family: 'Barlow';
    src: url(${BarlowMediumItalic});
    font-style: italic;
    font-weight: bold;
  }

  @font-face {
    font-family: 'Barlow';
    src: url(${BarlowBold});
    font-style: normal;
    font-weight: 800;
  }

  @font-face {
    font-family: 'Barlow';
    src: url(${BarlowBoldItalic});
    font-style: italic;
    font-weight: 800;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }
  *:focus {
    outline: 0;
  }
  *::-webkit-scrollbar{
    display: none;
  }

  html, body, #root {
    height: 100%;
    scrollbar-width: none !important;
  }
  body {
    -webkit-font-smoothing: antialiased !important;

    .fULWGz {
      height:auto;
      margin-bottom: 70px;
    }
    .MuiTypography-body1 {
      font-size: 14px;
      font-family: 'Barlow', sans-serif;
      font-weight: normal;
    }

    .MuiCheckbox-colorSecondary {
      &.Mui-checked {
        color: var(--secondary);

        &:hover {
          background-color: var(--secondary)
        }
      }

      &:hover {
        background-color: var(--secondary)
      }
    }

    .row {
      margin: 0;
    }
  }

  body, input, button {
    font-size: 14px;
    font-family: 'Barlow', sans-serif;
    font-style: normal;
    font-weight: normal;

    &:focus {
      outline: 0;
    }
  }
  a {
    text-decoration: none;

    &:hover {
      color: inherit;
      text-decoration: none;
    }
  }
  ul {
    list-style: none;
  }
  button {
    cursor: pointer;
  }

  .fc-theme-standard a {
    color: #272b2e;
  }

  .MuiTableCell-root {
    border: none !important;
  }

  :root {
    --primary:  #92286c;
    --secondary: #272b2e;
    --tertiary:  #e58e37;
    --quaternary: #eeeeee;
    --quinary: #393d42;
    --senary: #828386;
    --white: #fff;
    --gray: #d2d2d2;
    --symbol: #74777a;
    --notification: #f84a4b;
    --fc-button-bg-color: #272b2e;
    --fc-button-border-color: #272b2e;
    --fc-neutral-text-color: red;
  }
`;
