import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from './styles';
import EnhancedTableHead from '../../components/tableSociety';
import Animated from '~/components/Animated';
import { store } from '~/store';
import api from '~/services/api';
import TitleBar from '~/components/TitleBar';
import history from '~/services/history';
import AddButton from '~/components/AddButton';
import { castCnpj } from '~/utils/parsers';

export default function Society() {
  const { user } = store.getState().AuthReducer;
  const { token } = store.getState().AuthReducer;
  const dispatch = useDispatch();

  const [societies, setSocieties] = useState([]);

  function createData(nome, acesso, vermais, index) {
    return { nome, acesso, vermais, index };
  }

  useEffect(() => {
    const fetchSocieties = async () => {
      return await api(token).get('/api/societies');
    };

    fetchSocieties().then(response => {
      setSocieties(response.data.content ?? []);
    });
  }, [dispatch, token, user.id]);

  const rows =
    societies?.map((society, index) => {
      return createData(
        society.name,
        castCnpj(society.identification),
        society.id,
        index
      );
    }) || [];

  return (
    <Animated name="fadeIn" delay={200}>
      <TitleBar
        title="Sociedades"
        actions={
          <AddButton
            text="Nova Sociedade"
            onClick={() => {
              history.push('/societies/new');
            }}
          />
        }
      />
      <Container>
        <EnhancedTableHead rows={rows} />
      </Container>
    </Animated>
  );
}
