/* eslint-disable no-inner-declarations */
import React, { useState, useEffect } from 'react';

import { Form, Textarea } from '@rocketseat/unform';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Select from 'react-select';
import { Button } from '@material-ui/core';
import Animated from '~/components/Animated';
import api from '~/services/api';
import { Section, Content, Footer, InputContainer } from './styles';
import DatePicker from '~/components/DatePicker';
import history from '~/services/history';
import Input from '~/components/Input';
import { store } from '~/store';
import { requestProjectDetails } from '~/store/modules/project/actions';
import TitleBar from '~/components/TitleBar';
import { start, stop } from '~/store/modules/loader/actions';

function NewTask() {
  const dispatch = useDispatch();
  const { token } = store.getState().AuthReducer;
  const { projectId } = useSelector(state => state.SocietyReducer);
  const [form, setForm] = useState([]);

  /**
   * Events
   */
  const [events, setEvents] = useState();
  const [eventOptions, setEventOptions] = useState([])
  const [event, setEvent] = useState();

  /**
   * Parties
   */
  const { parties } = useSelector(state => state.ProjectReducer.project ?? []);
  const [selectedParties, setSelectedParties] = useState();
  const [partiesOptions, setPartiesOptions] = useState(parties.map(part => {
    return {
      label: part.person.name,
      value: part.person.id
    }
  }) ?? []);

  /**
   * Calendar
   */
  const [calendarOptions, setCalendarOptions] = useState([{
    label: 'São Paulo',
    value: 'Sp'
  }, {
    label: 'Rio de Janeiro',
    value: 'Rj'
  }, {
    label: 'Santa Catarina',
    value: 'Sc'
  }])
  const [calendar, setCalendar] = useState();

  /**
   * Type
   */
  const [limitType, setLimitType] = useState();
  const [limitTypeOptions, setLimitTypeOptions] = useState([{
    label: 'Data Fixa',
    value: 'FIXED_DATE'
  }, {
    label: 'Dias corridos após evento',
    value: 'DAYS'
  }, {
    label: 'Dias úteis após evento',
    value: 'BUSINESS_DAYS'
  }, {
    label: 'Meses após evento',
    value: 'MONTHS'
  }])

  useEffect(() => {
    async function getEvents() {
      dispatch(start());
      try {
        const response = await api(token).get(
          `/api/me/projects/${projectId}/events`
        );

        const eventCollection = response?.data ?? []

        setEvents(eventCollection);
        setEventOptions(eventCollection.map(evt => ({
          label: evt.name,
          value: evt.id
        })))

        dispatch(stop());
      } catch (e) {
        console.error(e)
      } finally {
        dispatch(stop());
      }
    }

    getEvents();
  }, [dispatch, projectId, token]);

  async function createTask(params) {
    dispatch(start());
    try {
      await api(token).post(`/api/me/projects/${projectId}/tasks`, params);
      dispatch(requestProjectDetails(token, projectId));
      toast.success('Tarefa criada!');
      history.go(-1)
    } catch (e) {
      toast.error('Aconteceu algum problema, tente novamente!');
    } finally {
      dispatch(stop());
    }
  }

  async function handleSubmit(data) {
    const body = {
      projectId,
      name: data.name,
      longDescription: data.longDescription,
      comments: data.comments,
      limit: {
        type: limitType.value,
        date: data?.date ? new Date(data.date).toISOString() : null,
        value: data?.numberDays,
        eventId: event?.value
      },
      personObligants: selectedParties?.map(item => item.value)
    }

    createTask(body);
  }

  function handleSelectedType(selected) {
    setLimitType(selected)
    setEvent(null)
    setCalendar(null)
  }

  function handleSelectedEvent(selected) {
    setEvent(selected)
  }

  function handleSelectedCalendar(selected) {
    setCalendar(selected)
  }

  function handleSelectedParties(selected) {
    setSelectedParties(selected);
  }

  return (
    <Animated name="fadeIn" delay={200}>
      <Form
        onSubmit={handleSubmit}>
        <TitleBar
          title="Nova Tarefa"
          canBack
          actions={
            <>
              <Button
                type="submit"
                variant="contained"
                disableElevation
                color="secondary"
                style={{ flex: '0 0 auto' }}>
                Salvar
              </Button>

              <Button
                variant="outlined"
                color="secondary"
                style={{ flex: '0 0 auto' }}
                onClick={() => history.goBack(-1)}>
                Cancelar
              </Button>
            </>
          }
        />

        <Content style={{ marginTop: '1rem' }}>
          <Input
            type="text"
            name="name"
            label="Nome"
            maxLength="75"
            placeholder="Reunião de acionistas majoritários"
            required
          />

          <Section>
            <InputContainer>
              <label htmlFor="tipo">Data Limite</label>
              <Select
                required
                name="type"
                color="secondary"
                options={limitTypeOptions}
                placeholder="Selecione o tipo de Data Limite"
                value={limitType}
                onChange={handleSelectedType}
              />
            </InputContainer>

            {limitType?.value === 'FIXED_DATE' && (
              <DatePicker
                name="date"
                label="Selecione a data limite"
                width={100}
                labelStyle={{ margin: '0' }}
                required
              />
            )}

            {['DAYS', 'BUSINESS_DAYS', 'MONTHS'].includes(limitType?.value) && (
              <InputContainer>
                <label>Evento</label>
                <Select
                  name="eventId"
                  color="secondary"
                  options={eventOptions}
                  placeholder="Selecione o Evento"
                  value={event}
                  onChange={handleSelectedEvent}
                />
              </InputContainer>
            )}

            {limitType?.value === 'DAYS' && (
              <>
                <Input
                  type="number"
                  name="numberDays"
                  label="Número de dias após Evento"
                  placeholder='10'
                  required
                />
              </>
            )}

            {limitType?.value === 'BUSINESS_DAYS' && (
              <>
                <Input
                  type="number"
                  name="numberDays"
                  label="Número de dias úteis após Evento"
                  placeholder='10'
                  required
                />

                <InputContainer>
                  <label>Calendário</label>
                  <Select
                    name="calendar"
                    color="secondary"
                    options={calendarOptions}
                    placeholder="Selecione as Partes"
                    value={calendar}
                    onChange={handleSelectedCalendar}
                    required
                  />
                </InputContainer>
              </>
            )}

            {limitType?.value === 'MONTHS' && (
              <>
                <Input
                  type="number"
                  name="numberDays"
                  label="Número de meses após Evento"
                  placeholder='3'
                  required
                />
              </>
            )}
          </Section>

          <Section>
            <InputContainer>
              <label>Partes</label>
              <Select
                required
                name="partes"
                color="secondary"
                options={partiesOptions}
                placeholder="Selecione as Partes"
                value={selectedParties}
                onChange={handleSelectedParties}
                isSearchable
                isMulti
              />
            </InputContainer>
          </Section>

          <Section>
            <InputContainer>
              <label>Condição</label>
              <Input
                type="text"
                name="condition"
                placeholder="Condição(Opcional)"
              />
            </InputContainer>
          </Section>

          <Section>
            <InputContainer>
              <label>Descrição detalhada</label>
              <Textarea
                name="longDescription"
                id="descricao"
                rows="3"
                style={{
                  background: 'white',
                  border: '1px solid rgba(83,63,76, .5)',
                  borderRadius: '5px',
                  padding: '0.5rem 1rem',
                  color: '#3b3c40',
                  cursor: 'pointer',
                  fontSize: '14px',
                  width: '100%',
                }}
                placeholder="Descrição detalhada da tarefa"
                required
              />
            </InputContainer>

            <InputContainer>
              <label>Observações</label>
              <Textarea
                name="comments"
                id="observacoes"
                rows="3"
                style={{
                  background: 'white',
                  border: '1px solid rgba(83,63,76, .5)',
                  borderRadius: '5px',
                  padding: '0.5rem 1rem',
                  color: '#3b3c40',
                  cursor: 'pointer',
                  fontSize: '14px',
                  width: '100%',
                }}
                placeholder="Observações(Opcional)"
              />
            </InputContainer>
          </Section>
        </Content>
      </Form>
    </Animated>
  );
}

export default NewTask;
