import React, { useState } from 'react';
import Animated from '~/components/Animated';
import DatePicker from '~/components/DatePicker';
import Stepper from '~/components/Stepper';
import history from '~/services/history';
import TableAssociate from '~/components/TableAssociate';

import { Container, Button, ButtonBack } from './styles';

function Integralizacao() {
  const [step, setStep] = useState(0);

  function createData(name, total, social, reserva) {
    return { name, total, social, reserva };
  }

  const rows = [
    createData('Cupcake', '400', '100', '300'),
    createData('Donut', '400', '50', '300'),
    createData('Eclair', '400,', '300', '300'),
    createData('Frozen yoghurt', '400', '900', '300'),
    createData('Gingerbread', '400', '100', '300'),
    createData('Honeycomb', '400', '200', '300'),
    createData('Ice cream sandwich', '400', '200', '300'),
  ];
  function Data() {
    return (
      <div className="data">
        <DatePicker name="data" label="Selecione a data" width={30} />
      </div>
    );
  }
  function Partner() {
    return (
      <div className="body">
        <TableAssociate rows={rows} />
      </div>
    );
  }
  function getStepContent(mainStep) {
    switch (mainStep) {
      case 0:
        return <Data />;
      case 1:
        return <Partner />;
      // case 2:
      //   return <Value />;
      // case 3:
      //   return <Resume />;
      default:
        return 'Unknown step';
    }
  }
  return (
    <>
      <Animated name="fadeIn" delay={200}>
        <Container>
          <Stepper stepMain={step} />
          <div className="dataInput">{getStepContent(step)}</div>
          <div className="buttons">
            <ButtonBack
              onClick={() => {
                if (step > 0) {
                  return setStep(step - 1);
                }
                return history.push('/societies');
              }}
            >
              VOLTAR
            </ButtonBack>
            <>
              {step === 3 ? (
                <Button
                  onClick={() => {
                    setStep(step + 1);
                  }}
                >
                  CONCLUIR
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setStep(step + 1);
                  }}
                >
                  AVANÇAR
                </Button>
              )}
            </>
          </div>
        </Container>
      </Animated>
    </>
  );
}

export default Integralizacao;
