import * as Yup from 'yup';

const validation = Yup.object({
  email: Yup.string()
    .email('E-mail inválido!')
    .required('O e-mail é obrigatório'),
  password: Yup.string()
    .min(4, 'A senha deve possuir no mínimo 4 dígitos')
    .required('A senha é obrigatória'),
});

export default validation;
