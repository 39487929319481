import styled from 'styled-components';

export const Button = styled.button`
  align-self: center;
  background: ${props => props.background};
  border: none;
  border-radius: ${props => (props.isCircle ? '50%' : '5px')};
  color: ${props => props.color};
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  height: ${props => `${props.height}px`};
  width: ${props => `${props.width}px`};
  transition: all 0.2s ease-in;

  &:hover {
    box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.15);
    transform: translateY(-2px);
  }
`;
