import React from 'react';

import { useSelector } from 'react-redux';
import TableDocuments from '../../../../components/TableDocuments';

import { Container } from './styles';
import { store } from '~/store';
import { useDispatch } from 'react-redux';
import { start, stop } from '~/store/modules/loader/actions';
import api from '~/services/api';
import { requestProjectDetails } from '~/store/modules/project/actions';
import { toast } from 'react-toastify';

function Documentos() {
  const dispatch = useDispatch();
  const { token } = store.getState().AuthReducer;
  const { projectId } = useSelector(state => state.SocietyReducer);
  const { project } = store.getState().ProjectReducer;
  const { documents } = useSelector(
    state => state.ProjectReducer.project ?? []
  );

  function createData(id, numero, titulo, link) {
    return { id, numero, titulo, link };
  }

  const rows = documents.map((d, i) => createData(d.id, d.number, d.title, i));
  
  async function deleteDocument(id) {
    dispatch(start());
    try {
      await api(token).delete(`/api/me/projects/${projectId}/documents/${id}`);
      dispatch(requestProjectDetails(token, projectId));
      toast.success('Parte deletada com sucesso!');
    } catch (e) {
      toast.error('Aconteceu algum problema, tente novamente!');
      return false
    } finally {
      dispatch(stop());
    }

    return true
  }

  async function deleteDocumentHandler(docs) {
    dispatch(start());
    docs.map(id => {
      if (deleteDocument(id)) {
        let i = docs.indexOf(id)
        if (i !== -1) {
          docs.splice(i, 1)
        }
      }
    });
    dispatch(stop());
  }

  return (
    <Container>
      <TableDocuments 
        rows={rows} 
        projeto={project} 
        deleteDocuments={deleteDocumentHandler}
      />
    </Container>
  );
}

export default Documentos;
