/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  FaEdit,
  FaHandHoldingUsd,
  FaLandmark,
  FaInfoCircle,
} from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import history from '~/services/history';
import { store } from '~/store';
import api from '~/services/api';
import { stop, start } from '~/store/modules/loader/actions';
import Animated from '~/components/Animated';
import { Container, Card, Description } from './styles';
import ModalInfo from '~/components/ModalInfo';

function Societies() {
  const dispatch = useDispatch();
  const { token } = store.getState().AuthReducer;
  const { societyId } = store.getState().SocietyReducer;
  const [show, setShow] = useState(false);
  const [society, setSociety] = useState();
  function openModal() {
    setShow(true);
  }

  useEffect(() => {
    const fetchSociety = async () => {
      dispatch(start());
      return await api(token).get(`/api/societies/${societyId}`);
    };

    fetchSociety()
      .then(response => {
        setSociety(response.data);
      })
      .finally(() => dispatch(stop()));
  }, [dispatch, societyId, token]);

  return (
    <>
      {society && (
        <>
          <ModalInfo
            name="modal-info"
            title="Informações da Sociedade"
            show={show}
            cancel={() => {
              setShow(false);
            }}>
            <Description>
              <p>Nome: {society?.name}</p>
              <p>Estado: {society?.state}</p>
              <p>Cidade: {society?.city}</p>
              <p>Bairro: {society?.neighborhood}</p>
              <p>Cep: {society?.cep}</p>
              <p>CNPJ: {society?.identification}</p>
            </Description>
          </ModalInfo>
          <Animated name="fadeIn" delay={200}>
            <Container>
              <div className="groupTop">
                <div className="head">
                  <div className="name">
                    <div className="info">
                      <h1>{society?.name}</h1>
                      <FaInfoCircle
                        color="var(--secondary)"
                        size={18}
                        onClick={() => {
                          openModal();
                        }}
                      />
                    </div>
                    <Link to="/editSocieties">
                      <FaEdit color="var(--secondary)" size={32} />
                    </Link>
                  </div>
                </div>
                <div className="division" />
                <div className="cards">
                  <Card>
                    <div className="initial">
                      <span className="title">Capital Social</span>
                      <FaHandHoldingUsd color="var(--secondary)" size={40} />
                    </div>
                    <span className="value">R$ 300,00</span>
                    <div className="bottom">
                      <span>Integralizado: 130</span>
                      <p>A integralizar: 12</p>
                    </div>
                  </Card>
                  <Card>
                    <div className="initial">
                      <span className="title">Reserva de capital</span>
                      <FaLandmark color="var(--secondary)" size={34} />
                    </div>
                    <span className="value">R$ 300,00</span>
                    <div className="bottom">
                      <span>Integralizado: 130</span>
                      <p>A integralizar: 12</p>
                    </div>
                  </Card>
                </div>
              </div>
              <div className="groupBottom">
                <Link to="/snapshot">
                  <span>Snapshot</span>
                </Link>
                <Link to="/addEvent">
                  <span>Adicionar Evento</span>
                </Link>
              </div>
              <button
                type="button"
                className="back"
                onClick={() => {
                  history.push('/society');
                }}>
                Voltar
              </button>
            </Container>
          </Animated>
        </>
      )}
    </>
  );
}

export default Societies;

Societies.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string,
    }),
  }).isRequired,
};
