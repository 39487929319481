import { combineReducers } from 'redux';

import { AuthReducer } from './auth/reducer';
import { SocietyReducer } from './society/reducer';
import { ProjectReducer } from './project/reducer';
import user from './user/reducer';
import { Loader } from './loader/reducer';
import menu from './menu/reducer';
import dashboard from './dashboard/reducer';

export default combineReducers({
  AuthReducer,
  user,
  Loader,
  menu,
  dashboard,
  SocietyReducer,
  ProjectReducer,
});
