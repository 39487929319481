function castDate(dateObj) {
  if (!dateObj) return '';
  return new Date(...dateObj).toLocaleString('pt-BR');
}

function castCnpj(v) {
  if (v?.toString().length === 14) {
    v = v.replace(/\D/g, '');
    v = v.replace(/^(\d{2})(\d)/, '$1.$2')
    v = v.replace(/^(\d{2})\.(\d{3})(\d)/, '$1.$2.$3')
    v = v.replace(/\.(\d{3})(\d)/, '.$1/$2')
    v = v.replace(/(\d{4})(\d)/, '$1-$2')
  }

  return v
}

export { castDate, castCnpj };
