import { all } from 'redux-saga/effects';

import auth from './auth/sagas';
import user from './user/sagas';
import resetPassword from './resetPassword/sagas';
import dashboard from './dashboard/sagas';
import project from './project/sagas';

export default function* rootSaga() {
  return yield all([auth, user, resetPassword, dashboard, project]);
}
