import React from 'react';
import { useSelector } from 'react-redux';

import { Container, Content } from './styles';

export default function Loader() {
  const show = useSelector(state => state.Loader.show);

  return (
    show && (
      <Container>
        <Content>
          <div className='spin-loader'></div>
        </Content>
      </Container>
    )
  );
}
