/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Toolbar from '@material-ui/core/Toolbar';
import { IoMdEye as DetailIcon } from 'react-icons/io';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import history from '~/services/history';
import { requestProjectDetails } from '~/store/modules/project/actions';
import { store } from '~/store';
import Input from '~/components/Input';

const useEnhancedToolbarStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    padding: 0,
    justifyContent: 'space-between',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  filter: {
    color: 'var(--secondary)',
    textDecoration: 'none',
    textTransform: 'capitalize',
    padding: '0.25rem 0',
    transition: 'all 0.5s',
    borderRadius: '100px',
    backgroundColor: 'transparent',
    '&:hover, &:focus': {
      color: '#5290ea',
    },
  },
  activeFilter: {
    fontWeight: 'bold',
    padding: '0.25rem 0.5rem',
    backgroundColor: 'rgba(83,63,76,.1)',
    transition: 'all 0.5s',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useEnhancedToolbarStyles();
  const { handleFilter } = props;
  const [filter, setFilter] = React.useState({
    status: null,
    textual: null,
  });

  const Action = props => {
    const { tabIndex, value, active, text, handleFilter } = props;
    return (
      <>
        <a
          role="button"
          className={classNames(
            classes.filter,
            active ? classes.activeFilter : undefined
          )}
          tabIndex={tabIndex}
          onKeyPress={() => false}
          onClick={() => {
            const _filter = { status: value, textual: filter.textual };
            handleFilter(_filter);
            setFilter(_filter);
          }}>
          {text}
        </a>
      </>
    );
  };

  return (
    <Toolbar className={classes.root}>
      <Input
        onChange={e => {
          const _filter = { status: filter.status, textual: e.target.value };
          handleFilter(_filter);
          setFilter(_filter);
        }}
        name="projects"
        placeholder="Busque projetos"
        style={{
          border: '1px solid rgba(128, 133, 139, 0.3)',
          borderRadius: '5px',
          flex: '1 1 auto',
          width: '90%',
          fontSize: '1em',
        }}
      />
      <div className={classes.actions}>
        <Action
          text="ativos"
          active={filter.status === 'ACTIVE'}
          tabIndex={-1}
          value="ACTIVE"
          handleFilter={handleFilter}
        />
        <Action
          text="arquivados"
          active={filter.status === 'INACTIVE'}
          tabIndex={-1}
          value="INACTIVE"
          handleFilter={handleFilter}
        />
        <Action
          text="todos"
          active={!filter.status}
          tabIndex={-1}
          value={null}
          handleFilter={handleFilter}
        />
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  handleFilter: PropTypes.func.isRequired,
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    margin: 0,
  },
  table: {
    margin: 0,
    minWidth: 100,
    borderCollapse: 'separate',
  },
  row: {
    padding: '10px 14px',
    height: '67px',
    border: 'none',
    '&:first-child': {
      borderTopLeftRadius: '10px',
      borderTopRightRadius: '10px',
    },
    '&:last-child': {
      borderBottomLeftRadius: '10px',
      borderBottomRightRadius: '10px',
    },
  },
  oddRow: {
    backgroundColor: 'rgb(250,247,250)',
    '&:hover, &:focus': {
      backgroundColor: '#ffffff',
    },
  },
  cell: {
    border: 'none',
    fontSize: '14px',
    '&:first-child': {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
    },
    '&:last-child': {
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
    },
  },
  visuallyHidden: {
    margin: 0,
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  title: {
    fontWeight: '500',
    fontSize: '16px',
    color: 'secondary',
  },
}));

export default function EnhancedTable(data) {
  const classes = useStyles();
  const rowsInitial = data.rows;
  const [projects, setProjects] = React.useState(data.rows);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const dispatch = useDispatch();
  const { token } = store.getState().AuthReducer;
  const [filter, setFilter] = React.useState({
    status: null,
    textual: null,
  });

  let rows = rowsInitial.filter(proj => {
    if (filter.status && filter.textual) {
      return (
        proj.nome === filter.status && proj.nome.includes(filter.textual)
      );
    }
    if (!filter.status && filter.textual) {
      return proj.nome?.includes(filter.textual);
    }
    if (filter.status && !filter.textual) {
      return proj.status === filter.status;
    }

    return true;
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, projects.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <EnhancedTableToolbar handleFilter={setFilter} />
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table">
          <TableBody>
            {rows
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .sort()
              .reverse()
              .map((row, index) => {
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row.index}
                    className={classNames(
                      classes.row,
                      index % 2 === 0 ? classes.oddRow : undefined
                    )}>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.cell}>
                      <Link
                        style={{
                          color: '#131313',
                        }}
                        onClick={() => {
                          dispatch(requestProjectDetails(token, row.vermais));
                          history.push('/projectDetail');
                        }}>
                        <span className={classes.title}>{row.nome}</span>
                        <br />
                        {row.descritivo}
                      </Link>
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      style={{
                        textAlign: 'right',
                        paddingRight: '10px',
                        fontSize: '25px',
                      }}
                      className={classes.cell}>
                      <Link
                        style={{
                          color: '#131313',
                        }}
                        onClick={() => {
                          dispatch(requestProjectDetails(token, row.vermais));
                          history.push('/projectDetail');
                        }}>
                        <DetailIcon />
                      </Link>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
