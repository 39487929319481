import LoaderTypes from './types';

const INITIAL_STATE = {
  show: false,
};

export const Loader = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LoaderTypes.START:
      return {
        ...state,
        show: true,
      };
    case LoaderTypes.STOP:
      return {
        ...state,
        show: false,
      };
    default:
      return state;
  }
};
