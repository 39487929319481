/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import {
  IoIosApps,
  IoIosArrowForward,
  IoIosGlobe,
  IoIosLogOut,
  IoIosSettings,
} from 'react-icons/all';
import Animated from '~/components/Animated';

import { persistor } from '~/store';
import { signOut as signOutAction } from '~/store/modules/auth/actions';

import { Container } from './styles';

export default function Menu() {
  const dispatch = useDispatch();
  const [adm, setAdm] = useState(false);

  const contentRef = useRef(null);

  function signOut() {
    persistor.purge();
    dispatch(signOutAction());
  }

  return (
    <Animated name="" delay={200}>
      <Container key="menu">
        <div className="options">
          <div className="navigable">
            <Link to="/society">
              <div className="group">
                <IoIosGlobe color="var(--primary)" size={20} />
                <span>Sociedades</span>
              </div>
              {/* <FaPlay size={16} /> */}
            </Link>
          </div>

          <div className="navigable">
            <Link to="/projects">
              <div className="group">
                <IoIosApps color="var(--primary)" size={20} />
                <span>Projetos</span>
              </div>
              {/* <FaPlay size={16} className="icon" /> */}
            </Link>
          </div>

          <div className="navigable">
            <Link
              onClick={() => {
                setAdm(!adm);
              }}
            >
              <div className="group">
                <IoIosSettings color="var(--primary)" size={20} />
                <span>Administração</span>
              </div>
              <IoIosArrowForward
                color="var(--primary)"
                size={15}
                className={`${adm ? 'open' : 'close'}`}
              />
            </Link>

            <div
              className="list"
              ref={contentRef}
              style={{
                visibility: `${adm ? 'visible' : 'hidden'}`,
                opacity: `${adm ? '1' : '0'}`,
                maxHeight: `${
                  adm ? `calc(${contentRef.current.scrollHeight}px)` : '0px'
                }`,
              }}
            >
              <Link to="/users">Usuários</Link>
              <Link to="/historyLogin">Histórico de Logins</Link>
              <Link to="/auditProject">Auditoria de Projetos</Link>
              <Link to="/auditDocs">Auditoria de Documentos</Link>
              <Link to="/auditEvent">Auditoria de Eventos</Link>
              <Link to="/auditMileston">Auditoria de Milestones</Link>
            </div>
          </div>

          <div className="navigable">
            <Link to="/" onClick={signOut}>
              <div className="group">
                <IoIosLogOut color="var(--primary)" size={20} />
                <span>Sair</span>
              </div>
            </Link>
          </div>
        </div>
      </Container>
    </Animated>
  );
}
