/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IconButton, Tab, Tabs, styled } from '@material-ui/core';
import Animated from '~/components/Animated';
import { Container, Summary } from './styles';
import Partes from './Partes';
import Documentos from './Documentos';
import Milestones from './Milestone';
import Participants from './Participants';
import Tarefa from './Tarefa';
import { store } from '~/store';
import TitleBar from '~/components/TitleBar';
import Calendar from '../Calendar';
import api from '~/services/api';
import history from '~/services/history';
import { BsFillGearFill } from 'react-icons/bs';

const StyledTabs = styled(props => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 50,
    width: '100%',
  },
});

const StyledTab = styled(props => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    padding: '9px',
    marginRight: 0,
    color: '#533f4c',
    '&.Mui-selected': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#533f4c',
    },
  })
);

function ProjectDetail() {
  const dispatch = useDispatch();
  const [parties, setParties] = useState();
  const [documents, setDocuments] = useState();
  const [milestones, setMilestones] = useState();
  const [society, setSociety] = useState();
  const { token } = store.getState().AuthReducer;
  const { projectId } = store.getState().SocietyReducer;
  const { project } = store.getState().ProjectReducer;
  const [tasks, setTasks] = useState([]);

  function castDate(dateObj) {
    if (!dateObj) {
      return ''
    }

    return new Date(dateObj.join('/')).toLocaleDateString('pt-BR')
  }

  const [tabs] = useState([
    {
      label: 'Resumo',
      component: (
        <>
          <Summary>
            <p>
              <strong>Sumário:</strong> {project?.summary}
            </p>
            <p>
              <strong>Data de Início:</strong> {castDate(project?.startDate)}
            </p>
            {project?.endDate && (
              <p>
                <strong>Data de Conclusão:</strong> {castDate(project?.endDate)}
              </p>
            )}
          </Summary>
        </>
      ),
    },
    {
      label: 'Agenda',
      component: <Calendar origin={`/api/me/projects/${projectId}/tasks`} />,
    },
    {
      label: 'Partes',
      component: <Partes project={project} />,
    },
    {
      label: 'Participantes',
      component: <Participants project={project} />,
    },
    {
      label: 'Documentos',
      component: <Documentos />,
    },
    {
      label: 'Tarefa',
      component: <Tarefa />,
    },
    {
      label: 'Milestones',
      component: <Milestones />,
    },
  ]);

  const getCurrentPage = () => {
    const { hash } = history.location;
    const page = tabs.findIndex(t => {
      return hash.replace('#', '') === t.label;
    });

    return page > 0 ? page : 0;
  };

  const [selectedTab, setSelectedTab] = React.useState(getCurrentPage());
  const [selectedTabContent, setSelectedTabContent] = React.useState(
    tabs[getCurrentPage()].component
  );

  const changeTab = (evt, val) => {
    setSelectedTab(val);
    setSelectedTabContent(tabs[val].component);
    history.replace(`#${tabs[val].label}`);
  };

  /**/

  return (
    <>
      <TitleBar
        title={project?.name}
        href='/projects'
        actions={
          <>
            <StyledTabs
              value={selectedTab}
              onChange={changeTab}
              indicatorColor="primary"
              style={{ float: 'right' }}>
              {tabs.map((tab, id) => (
                <StyledTab key={tab.label} id={id} label={tab.label} />
              ))}
            </StyledTabs>

            <IconButton size='medium' dense onClick={() => false}>
              <BsFillGearFill />
            </IconButton>
          </>
        }
      />

      <Container>
        <Animated name="fadeIn" delay={200}>
          {selectedTabContent}
        </Animated>
      </Container>
    </>
  );
}

export default ProjectDetail;
