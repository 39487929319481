import React from 'react';
import PropTypes from 'prop-types';

import Header from '~/components/Header';
import Menu from '~/components/Menu';

import { Wrapper, Content } from './styles';

export default function DefaultLayout({ routeName, children }) {
  return (
    <Wrapper>
      <Header route={routeName} />
      <div className="body">
        <Menu />
        <Content>{children}</Content>
      </div>
    </Wrapper>
  );
}

DefaultLayout.propTypes = {
  children: PropTypes.element.isRequired,
  routeName: PropTypes.string.isRequired,
};
