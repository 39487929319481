import React from 'react';
import { styled } from '@material-ui/core/styles';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { Container } from './styles';
import Animated from '~/components/Animated';
import Table from '~/pages/Projects/Table';
import Calendar from '~/pages/Projects/Calendar';
import history from '~/services/history';
import AddButton from '~/components/AddButton';
import TitleBar from '~/components/TitleBar';

const tabs = [
  {
    label: 'Projetos',
    component: <Table />,
  },
  {
    label: 'Calendário',
    component: <Calendar origin='/api/me/tasks' />,
  },
];

const StyledTabs = styled(props => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 50,
    width: '100%',
  },
});

const StyledTab = styled(props => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: '#533f4c',
    '&.Mui-selected': {
      fontWeight: theme.typography.fontWeightBold,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#533f4c',
    },
  })
);

export default function Projects() {
  const [selectedTab, setSelectedTab] = React.useState(0);
  const [selectedTabContent, setSelectedTabContent] = React.useState(
    tabs[0].component
  );

  function changeTab(evt, val) {
    setSelectedTab(val);
    setSelectedTabContent(tabs[val].component);
  }

  return (
    <Animated name="fadeIn" delay={200}>
      <>
        <TitleBar
          title="Projetos"
          actions={
            <>
              <StyledTabs
                value={selectedTab}
                onChange={changeTab}
                indicatorColor="primary"
                style={{ float: 'right' }}>
                {tabs.map((tab, id) => (
                  <StyledTab key={tab.label} id={id} label={tab.label} />
                ))}
              </StyledTabs>

              <AddButton
                text="Novo Projeto"
                onClick={() => {
                  history.push('/newProject');
                }}
              />
            </>
          }
        />

        <Container>{selectedTabContent}</Container>
      </>
    </Animated>
  );
}
