import React from 'react';
import PropTypes from 'prop-types';
import { BsArrowLeftShort } from 'react-icons/all';
import { useHistory, useLocation } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import { Box } from '~/components/TitleBar/style';

export default function TitleBar({ title, subtitle, adds, actions, canBack, href = null }) {
  const history = useHistory();
  const location = useLocation();
  const isRootPage = location.pathname.split('/').length - 1 <= 1;

  return (
    <Box>
      <div className="titleBar">
        <div className="titleBar-breadcrumb" />
        <div className="titleBar-back">
          {(canBack || href) && (
            <IconButton dense onClick={() => href ? history.push(href) : history.go(-1)}>
              <BsArrowLeftShort />
            </IconButton>
          )}
        </div>
        <div className="titleBar-title">
          { subtitle && (
            <p>{subtitle}</p>
          ) }
          <h3 style={{fontWeight: '600'}}>{title}</h3>
          {adds}
        </div>
        <div className="titleBar-actions">{actions}</div>
      </div>
    </Box>
  );
}

TitleBar.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  actions: PropTypes.element.isRequired,
  canBack: PropTypes.bool,
};
