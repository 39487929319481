import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.01);
  z-index: 10000;
  animation: unhide 1s ease-in-out;
`;

export const Content = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  img {
    width: 100px;
  }

  .spin-loader {
    position: absolute;
    bottom: 0%;
    left: 50%;
    width: 100vw;
    height: 30vh;
    background: rgb(146,40,108);
    background: linear-gradient(0deg, rgba(146,40,108,0.2) 0%, rgba(146,40,108,0) 100%);
    transform: translateX(-50%);
  }
  .spin-loader::after {
    content: ' ';
    position: absolute;
    bottom: 40%;
    left: 50%;
    transform: translate(-50%, -40%);
    width: 35px;
    height: 35px;
    border-radius: 1000px;
    border: 5px solid var(--primary);
    border-left-color: transparent;
    animation: rotate 1s linear infinite;
  }

  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes unhide {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
