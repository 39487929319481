import styled from 'styled-components';

export const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
`;

export const Content = styled.div`
  /* width: 100%; */
  /* max-width: 315px; */
  text-align: center;
  z-index: 1;
`;
