import styled from 'styled-components';

export const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;

  button {
    margin-right: 0 !important;
  }

  span {
    color: var(--secondary);
    font-weight: bold;
    font-size: 22px;
  }
`;

export const ModalBody = styled.div`
  border-radius: 10px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  button {
    margin-left: auto;
  }
`;
