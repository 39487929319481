/* eslint-disable no-inner-declarations */
import React, { useState } from 'react';
import axios from 'axios';

import { Form } from '@rocketseat/unform';
import { toast } from 'react-toastify';
import Animated from '~/components/Animated';
import InputFormat from '~/components/NumberFormatInput';
import Input from '~/components/Input';
import history from '~/services/history';
import api from '~/services/api';
import { NewSocietyValidation } from '~/validations';
import { store } from '~/store';
import TitleBar from '~/components/TitleBar';
import { Button } from '@material-ui/core';
import { Section, Content, Adress } from './styles';

function NewSociety() {
  const { token } = store.getState().AuthReducer;
  const baseURL = 'https://viacep.com.br/ws';
  const apiCep = axios.create({ baseURL });

  const [cepI, setCepI] = useState('');
  const [logradouroI, setLogradouroI] = useState('');
  const [cidadeI, setCidadeI] = useState('');
  const [bairroI, setBairroI] = useState('');
  const [estadoI, setEstadoI] = useState('');
  const [type, setType] = useState('LTDA');

  async function submitSociety(data) {
    try {
      await api(token).post('/api/societies', data);
      toast.success('Sociedade criada com sucesso!');
      history.push('/society');
    } catch (e) {
      toast.error('Aconteceu algo, tente novamente!');
      console.log('deu ruim');
    }
  }

  async function mountRequest(data) {
    const mountPayload = {
      id: data.name,
      societyType: type,
      name: data.name,
      cnpj: data.cnpj,
      address: data.logradouro,
      number: data.numero,
      complement: data.complemento,
      neighborhood: data.bairro,
      cep: data.cep,
      city: data.cidade,
      state: data.estado,
    };

    submitSociety(mountPayload);
  }
  if (cepI.length === 8) {
    async function loadCep() {
      const { data } = await apiCep.get(`/${cepI}/json`);
      setLogradouroI(data.logradouro);
      setCidadeI(data.localidade);
      setBairroI(data.bairro);
      setEstadoI(data.uf);
    }
    loadCep();
  }
  const initialData = {
    logradouro: logradouroI,
    cidade: cidadeI,
    bairro: bairroI,
    estado: estadoI,
  };
  return (
    <>
      <Animated name="fadeIn" delay={200}>
        <Form
          onSubmit={mountRequest}
          schema={NewSocietyValidation}
          initialData={initialData}>
          <TitleBar
            title="Nova Sociedade"
            canBack
            actions={
              <>
                <Button
                  variant="contained"
                  disableElevation
                  color="secondary"
                  style={{ flex: '0 0 auto' }}
                  type="submit">
                  Salvar
                </Button>

                <Button
                  variant="outlined"
                  color="secondary"
                  style={{ flex: '0 0 auto' }}
                  onClick={() => history.goBack(-1)}>
                  Cancelar
                </Button>
              </>
            }
          />

          <Content>
            <Section>
              <label>Nome</label>
              <Input name="name" />
            </Section>
            <Section>
              <label>Tipo</label>
              <select
                name="tipo"
                id="programs"
                onChange={e => {
                  setType(e.target.value);
                }}>
                <option value="LTDA">LTDA</option>
                <option value="SA">SA</option>
              </select>
            </Section>
            <Section>
              <label>CNPJ</label>
              <InputFormat
                name="cnpj"
                format="##.###.###/####-##"
              // width={100}
              // widthType="%"
              />
            </Section>
            <Section>
              <label>CEP</label>
              <InputFormat
                name="cep"
                format="#####-###"
                onChange={e => {
                  setCepI(e.value);
                }}
              />
            </Section>
            <Adress>
              <div>
                <label>Logradouro</label>
                <Input name="logradouro" defaultValue={logradouroI} />
              </div>
              <div>
                <label>Número</label>
                <Input name="numero" />
              </div>
            </Adress>
            <Adress>
              <div>
                <label>Bairro</label>
                <Input name="bairro" />
              </div>
              <div>
                <label>Compl.</label>
                <Input name="complemento" />
              </div>
            </Adress>
            <Adress>
              <div>
                <label>Cidade</label>
                <Input name="cidade" />
              </div>
              <div>
                <label>Estado (sigla)</label>
                <Input name="estado" />
              </div>
            </Adress>
          </Content>
        </Form>
      </Animated>
    </>
  );
}

export default NewSociety;
