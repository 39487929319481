import React from 'react';
import { useDispatch } from 'react-redux';
import { Form } from '@rocketseat/unform';
import { Link } from 'react-router-dom';

import { FaGoogle, FaWindows } from 'react-icons/fa';
import Button from '~/components/Button';
import Input from '~/components/Input';
import Animated from '~/components/Animated';

import { signInRequest } from '~/store/modules/auth/actions';

import { SignInValidation } from '~/validations';

import logo from '~/assets/img/figo.png';

import { Container, Logo } from './styles';

export default function SignIn() {
  const dispatch = useDispatch();

  function handleSubmit({ email, password }) {
    dispatch(signInRequest(email, password));
  }

  return (
    <Animated name="fadeIn" delay={500}>
      <Container>
        <Logo src={logo} />
        <Form schema={SignInValidation} onSubmit={handleSubmit}>
          <Input
            autoCapitalize="off"
            name="email"
            placeholder="e-mail"
            placeholderAlign="center"
            width={350}
            widthType="px"
          />
          <Input
            type="password"
            name="password"
            placeholder="senha"
            placeholderAlign="center"
            width={350}
            widthType="px"
          />
          <Button type="submit" background="var(--primary)">
            Login
          </Button>
        </Form>
        {/* <Link to="/forgot" className="forgot">
          Esqueci minha senha
        </Link>
        <Link to="/register" className="register">
          <FaWindows color="var(--primary)" size={26} />
          Conta Office 365
        </Link>
        <Link to="/register" className="register">
          <FaGoogle color="var(--primary)" size={26} />
          Conta Google
        </Link> */}
        <p>Versão: DEV 1.0.0</p>
      </Container>
    </Animated>
  );
}
