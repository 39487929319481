import { takeLatest, all, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import DashboardService from '~/services/dashboard';

import * as LoaderActions from '~/store/modules/loader/actions';
import * as DashboardActions from './actions';

import DashboardTypes from './types';

export function* getNumbersCars() {
  try {
    yield put(LoaderActions.start());

    const response = yield call(DashboardService.index);

    yield put(DashboardActions.getNumberCarsSuccess(response.data));
  } catch (e) {
    toast.error(
      'Ocorreu algum erro ao buscar os dados, tente novamente mais tarde!'
    );
  } finally {
    yield put(LoaderActions.stop());
  }
}

export default all([
  takeLatest(DashboardTypes.GET_NUMBERS_CARS_REQUEST, getNumbersCars),
]);
