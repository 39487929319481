import SocietyTypes from './types';

export function selectSocietyId(token) {
  return {
    type: SocietyTypes.SELECT_SOCIETY_TOKEN,
    payload: { token },
  };
}
export function selectProjectId(token) {
  return {
    type: SocietyTypes.SELECT_PROJECT_TOKEN,
    payload: { token },
  };
}
