import React from 'react';
import { useParams } from 'react-router-dom';

import { Container, Content } from './styles';
import Animated from '~/components/Animated';
import TitleBar from '~/components/TitleBar';
import { store } from '~/store';
import { useSelector } from 'react-redux';
import history from '~/services/history';
import { Button, ButtonGroup, Chip, Divider } from '@material-ui/core';
import { BsLink, BsPeople } from 'react-icons/bs';


function DocDetail() {
  const { id } = useParams()
  const { project } = store.getState().ProjectReducer;
  const { documents } = useSelector(
    state => state.ProjectReducer.project ?? []
  );

  const document = documents.find(doc => doc.id === id)
  if (!document) {
    history.go(-1)
  }

  return (
    <>
      <Animated name="fadeIn" delay={200}>
        <TitleBar 
          title={document.title} 
          subtitle={project.name} 
          canBack 
        />
        
        <Container>
          <Content>
            <div style={{marginBottom: '3rem'}}>
              <p><strong>Partes:</strong></p>
              {document.parties.map(part => (
                <Chip 
                  icon={<BsPeople />} 
                  label={part.name} 
                  size="medium"
                  color="success" 
                  variant="outlined"
                />
              ))}
            </div>

            <ButtonGroup color="secondary" aria-label="Medium-sized button group">
              {document.externalLink && (
                <Button
                  color="secondary"
                  href={document.externalLink}
                  target='_blank'
                  startIcon={<BsLink />}
                >Visualizar Documento</Button>
              )}

              {document.externalLink && (
                <Button
                  color="secondary"
                  href={document.externalLinkPdf}
                  target='_blank'
                  startIcon={<BsLink />}
                >Visualizar Documento PDF</Button>
              )}
            </ButtonGroup>


          </Content>
        </Container>
      </Animated>
    </>
  );
}

export default DocDetail;
