import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  background-color: #ffff;
  margin: auto;
  margin-top: 40px;
  border-radius: 5px;
  margin-bottom: 50px;
  .back {
    outline-style: none;
    border: none;
    background: none;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    transition: all 0.2s linear;
    color: var(--secondary);
    margin-bottom: 10px;
    &:hover {
      transform: translateY(-5px);
    }
  }

  header {
    font-style: italic;
    text-align: center;
    color: var(--secondary);
    font-weight: bold;
    font-size: 28px;
    width: 90%;
    border-bottom: 1px solid var(--secondary);
    padding: 10px;
  }
  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > button {
      padding: 10px;
      background: none;
      width: 90%;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      background: #eee9e9;
      color: #010c16;
      border: 1px solid #eee9e9;
      margin: auto;
      margin-bottom: 20px;
      border-radius: 10px;
      margin-top: 30px;
      transition: all 0.2s linear;
      &:hover {
        box-shadow: 2px 5px 15px 1px rgba(0, 0, 0, 0.2);
        transform: translateY(-5px);
      }
    }
  }
`;
export const Section = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: auto;
  gap: 1rem;
  .group {
    flex-direction: column;
  }
  span {
    color: var(--secondary);
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 5px;
  }

  > input {
    background-color: #ffffff;
    border: 1px solid var(--primary);
    border-radius: 10px;
    color: #3b3c40;
    height: 40px;
    line-height: 16px;
    transition: all 0.2s ease;
    padding-left: 10px;
    &:hover {
      transform: translateY(-3px);
    }
    &::placeholder {
      color: #cccccc;
      padding-left: 10px;
    }
  }
  label {
    color: var(--secondary);
    font-weight: bold;
    font-size: 16px;
    margin-left: 5px;
    margin-bottom: 5px;
  }
  p {
    color: #eee9e9;
    text-align: justify;
  }
  > select {
    border: 1px solid var(--secondary);
    color: #010c16;
    font-weight: bold;
    padding-left: 20px;
    height: 40px;
    transition: all 0.2s linear;
    &:hover {
      transform: translateY(-3px);
      cursor: pointer;
    }

    option {
      background: #eee9e9;
      border-radius: 5px;
      color: #010c16;
      font-weight: bold;
    }
  }
`;
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 50%;
  max-width: 1000px;
  margin: auto;
`;

export const InputContainer = styled.div`
  label {
    margin-bottom: 5px;
    font-size: 16px;
  }
`;