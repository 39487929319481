import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .datepicker-custom {
    border: 1px solid rgba(83,63,76, .5);
    border-radius: 5px;
    color: #3b3c40;
    padding: 0.5rem 1rem;
    width: ${props => `${props.width}${props.widthtype}`};
    transition: all 0.2s ease;

    .react-calendar__tile--active {
      background: var(--secondary);
    }

    .react-date-picker__wrapper {
      border: none;
      width: 100%;
    }

    &.react-date-picker--disabled {
      background-color: var(--secondary);
      user-select: none;
      pointer-events: none;
    }

    &:focus {
      box-shadow: 0px 0px 7px 0px var(--secondary);
      color: var(--secondary);
    }

    &::placeholder {
      color: #cccccc;
    }

    & + span {
      align-self: flex-start;
      color: #fb5d62;
      font-size: 10px;
      font-weight: bold;
      margin: 0 5px 10px;
    }
  }
`;

export const Label = styled.span`
  margin-bottom: 5px;
  font-weight: bold
  font-size: 16px;
`;
