import styled from 'styled-components';
import posed from 'react-pose';

const BackdropProps = posed.div({
  init: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
  },
});

const ContentProps = posed.div({
  init: {
    opacity: 0,
    y: -20,
  },
  enter: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 20,
  },
});

export const Backdrop = styled(BackdropProps)`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
`;

export const Content = styled(ContentProps)`
  position: relative;
  max-width: 700px;
  width: 100%;
  height: auto;
  background-color: white;
  border-radius: 10px;
  padding: 2rem;
  z-index: 4;
  @media (max-width: 900px) {
    margin: 0;
  }
`;
