import axios from 'axios';

const api = token => {
  const client = axios.create({
    baseURL: process.env.REACT_APP_API_BACKEND,
    headers: {
      Authorization: `Bearer ${token}`,
    }
  })

  return client
};

export default api;
