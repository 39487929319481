import React, { useState } from 'react';
import Animated from '~/components/Animated';
import DatePicker from '~/components/DatePicker';
import Stepper from '~/components/StepperReducao';
import history from '~/services/history';
import InputNumber from '~/components/NumberFormatInput';

import { Container, Button, ButtonBack, Section } from './styles';

function Reducao() {
  const [step, setStep] = useState(0);

  function createData(name) {
    return { name };
  }

  const rows = [
    createData('Cupcake'),
    createData('Donut'),
    createData('Eclair'),
    createData('Frozen yoghurt'),
    createData('Gingerbread'),
    createData('Honeycomb'),
    createData('Ice cream sandwich'),
  ];
  function Data() {
    return (
      <div className="data">
        <DatePicker name="data" label="Selecione a data" width={30} />
        <Section>
          <label>Motivo de cancelamento: </label>
          <div className="group">
            <input
              type="checkbox"
              id="prejuizo"
              name="prejuizo"
              value="prejuizo"
            />
            <label>Absorção de prejuízo</label>
          </div>
          <div className="group">
            <input
              type="checkbox"
              id="capital"
              name="capital"
              value="capital"
            />
            <label>Excesso de capital</label>
          </div>
          <div className="group">
            <input type="checkbox" id="acoes" name="acoes" value="acoes" />
            <label>Cancelamento de ações</label>
          </div>
        </Section>
      </div>
    );
  }
  function Partner() {
    return (
      <div className="data">
        <InputNumber name="reducao" label="Valor a reduzir" defaultValue="0" />
      </div>
    );
  }
  function getStepContent(mainStep) {
    switch (mainStep) {
      case 0:
        return <Data />;
      case 1:
        return <Partner />;
      // case 2:
      //   return <Value />;
      // case 3:
      //   return <Resume />;
      default:
        return 'Unknown step';
    }
  }
  return (
    <>
      <Animated name="fadeIn" delay={200}>
        <Container>
          <Stepper stepMain={step} />
          <div className="dataInput">{getStepContent(step)}</div>
          <div className="buttons">
            <ButtonBack
              onClick={() => {
                if (step > 0) {
                  return setStep(step - 1);
                }
                return history.push('/societies');
              }}
            >
              VOLTAR
            </ButtonBack>
            <>
              {step === 3 ? (
                <Button
                  onClick={() => {
                    setStep(step + 1);
                  }}
                >
                  CONCLUIR
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setStep(step + 1);
                  }}
                >
                  AVANÇAR
                </Button>
              )}
            </>
          </div>
        </Container>
      </Animated>
    </>
  );
}

export default Reducao;
