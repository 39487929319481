import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Container } from './styles';
import TablePartes from '~/components/TablePartes';
import { start, stop } from '~/store/modules/loader/actions';
import api from '~/services/api';
import { store } from '~/store';
import { requestProjectDetails } from '~/store/modules/project/actions';

function Partes({ project }) {
  const dispatch = useDispatch();
  const { token } = store.getState().AuthReducer;
  const { parties } = useSelector(state => state.ProjectReducer.project);
  const { projectId } = useSelector(state => state.SocietyReducer);

  function createData(name, cpf, tipo, row) {
    return { name, cpf, tipo, row };
  }

  const rows =
    parties.map(p =>
      createData(p.person.name, p.person.cpf ?? p.person.cnpj, p.person.type, p)
    ) ?? [];

  async function createProject(params) {
    dispatch(start());
    const idProject = params.projectId;
    try {
      await api(token).post(`/api/me/projects/${idProject}/parties`, params, {
        method: 'POST'
      });
      dispatch(requestProjectDetails(token, idProject));
      toast.success('Parte criada com sucesso!');
    } catch (e) {
      toast.error('Aconteceu algum problema, tente novamente!');
    } finally {
      dispatch(stop());
    }
  }

  async function submitDelete(params) {
    dispatch(start());
    try {
      await api(token).delete(`/api/me/projects/${projectId}/parties/${params}`, {
        method: 'DELETE'
      });
      dispatch(requestProjectDetails(token, projectId));
      toast.success('Parte deletada com sucesso!');
    } catch (e) {
      toast.error('Aconteceu algum problema, tente novamente!');
      return false
    } finally {
      dispatch(stop());
    }

    return true
  }

  async function deleteParties(params) {
    dispatch(start());
    params.map(i => {
      if (submitDelete(i.row.person.id)) {
        if (i !== -1) {
          params.splice(i, 1)
        }
      }
    });
    dispatch(requestProjectDetails(token, projectId));
    dispatch(stop());
  }

  return (
    <>
      <Container>
        <TablePartes
          rows={rows}
          project={project}
          createProject={createProject}
          deleteParties={deleteParties}
        />
      </Container>
    </>
  );
}

export default Partes;
Partes.propTypes = {
  project: PropTypes.shape().isRequired,
};
