/* eslint-disable no-inner-declarations */
import React, { useRef, useState } from 'react';

import Animated from '~/components/Animated';
import { Content, InputContainer } from './styles';
import DatePicker from '~/components/DatePicker';
import history from '~/services/history';
import Input from '~/components/Input';
import { Button } from '@material-ui/core';
import TitleBar from '~/components/TitleBar';
import { useDispatch, useSelector } from 'react-redux';
import { store } from '~/store';
import api from '~/services/api';
import { toast } from 'react-toastify';
import { start, stop } from '~/store/modules/loader/actions';
import { requestProjectDetails } from '~/store/modules/project/actions';
import { Form } from '@rocketseat/unform';

function NewMilestone() {
  const dispatch = useDispatch();
  const { token } = store.getState().AuthReducer;
  const { project } = store.getState().ProjectReducer;
  const { projectId } = useSelector(state => state.SocietyReducer);

  async function createMilestone(params) {
    dispatch(start());
    try {
      await api(token).post(`/api/me/projects/${projectId}/milestones`, params);
      dispatch(requestProjectDetails(token, projectId));
      toast.success('Milestone criado!');
      history.go(-1)
    } catch (e) {
      toast.error('Aconteceu algum problema, tente novamente!');
    } finally {
      dispatch(stop());
    }
  }

  function handleSubmit(data) {
    const body = {
      ...data,
      completionDate: new Date(data.completionDate).toISOString(),
    }

    createMilestone(body)

    return false;
  }

  return (
    <>
      <Animated name="fadeIn" delay={200}>
        <Form onSubmit={handleSubmit}>
          <TitleBar
            title='Novo Milestone'
            subtitle={project.name}
            canBack={true}
            actions={
              <>
                <Button
                  variant="contained"
                  disableElevation
                  color='secondary'
                  style={{ flex: '0 0 auto' }}
                  type="submit">
                  Salvar
                </Button>

                <Button
                  variant="outlined"
                  color='secondary'
                  style={{ flex: '0 0 auto' }}
                  onClick={() => history.goBack(-1)}>
                  Cancelar
                </Button>
              </>
            }
          />

          <Content>
            <Input
              label="Título"
              name="name"
              maxLength="45"
              placeholder="Assinatura Contratual"
              required
            />

            <DatePicker
              name="completionDate"
              label="Data de Conclusão"
              width={100}
              required
            />

            <InputContainer>
              <label>Descrição detalhada</label>
              <Input
                name="longDescription"           
                required
              />
            </InputContainer>

            <InputContainer>
              <label>Observações</label>
              <Input
                name="comments"
                required
              />
            </InputContainer>
          </Content>
        </Form>
      </Animated>
    </>
  );
}

export default NewMilestone;
