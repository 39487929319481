import * as Yup from 'yup';

const validation = Yup.object({
  company: Yup.object({
    cnpj: Yup.string().required('O CNPJ é obrigatório'),
    fantasy_name: Yup.string().required('O nome fantasia é obrigatório'),
  }),
  user: Yup.object({
    email: Yup.string()
      .email('E-mail inválido')
      .required('O e-mail é obrigatório'),
    password: Yup.string()
      .min(6, 'A senha deve possuir no mínimo 6 dígitos')
      .required('A senha é obrigatória'),
    confirm_password: Yup.string().required(
      'A confirmação da senha é obrigatório!'
    ),
  }),
});

export default validation;
