import api from './apiToken';

const qs = require('qs');

// username = 'user@figo.com.br', password = '1234'

const store = ({ username, password }) => {
  const body = {
    grant_type: 'password',
    username,
    password,
    client_id: 'figo-frontend',
    client_secret: process.env.REACT_APP_TOKEN_KEYCLOAK,
  };
  return api.post(
    `/realms/figo/protocol/openid-connect/token`,
    qs.stringify(body),
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    }
  );
};

export default { store };
