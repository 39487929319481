import React from 'react';
import { Switch } from 'react-router-dom';

import Route from '~/routes/Route';

import SignIn from '~/pages/SignIn';
import ForgotPassword from '~/pages/ForgotPassword';
import Users from '~/pages/Users';
import AuditDocs from '~/pages/AuditDocs';
import AuditEvent from '~/pages/AuditEvent';
import AuditMileston from '~/pages/AuditMileston';
import AuditProject from '~/pages/AuditProject';
import HistoryLogin from '~/pages/HistoryLogin';
import NewSociety from '~/pages/Society/NewSociety';
import NewProject from '~/pages/Projects/NewProject';
import Societies from '~/pages/Society/Societies';
import EditSocieties from '~/pages/Society/Societies/EditSocieties';
import Snapshot from '~/pages/Society/Societies/Snapshot';
import AddEvent from '~/pages/Society/Societies/AddEvent';
import ProjectDetail from '~/pages/Projects/ProjectDetail';
import NewTask from '~/pages/Projects/ProjectDetail/Tarefa/NewTask';
import NewDocument from '~/pages/Projects/ProjectDetail/Documentos/NewDocument';
import TaskDetail from '~/pages/Projects/ProjectDetail/Tarefa/TaskDetail';
import DocDetail from '~/pages/Projects/ProjectDetail/Documentos/DocDetail';
import NewMilestone from '~/pages/Projects/ProjectDetail/Milestone/NewMilestone';
import MilestoneDetail from '~/pages/Projects/ProjectDetail/Milestone/MilestoneDetail';

import Society from '~/pages/Society';
import Projects from '~/pages/Projects';

export default function Routes() {
  return (
    <Switch>
      {/* Public Routes */}
      <Route exact name="Acesso" path="/" component={SignIn} />
      <Route
        exact
        name="Esqueci minha senha"
        path="/forgot"
        component={ForgotPassword}
      />

      {/* Private Routes */}

      <Route
        exact
        name="Sociedades"
        path="/society"
        component={Society}
        isPrivate
      />
      <Route
        exact
        name="Projetos"
        path="/projects"
        component={Projects}
        isPrivate
      />
      <Route exact name="Usuários" path="/users" component={Users} isPrivate />
      <Route
        exact
        name="Histórico de logins"
        path="/historyLogin"
        component={HistoryLogin}
        isPrivate
      />
      <Route
        exact
        name="Auditoria de Eventos"
        path="/auditEvent"
        component={AuditEvent}
        isPrivate
      />
      <Route
        exact
        name="Auditoria de Documentos"
        path="/auditDocs"
        component={AuditDocs}
        isPrivate
      />
      <Route
        exact
        name="Auditoria de Projetos"
        path="/auditProject"
        component={AuditProject}
        isPrivate
      />
      <Route
        exact
        name="Auditoria de Eventos"
        path="/auditEvent"
        component={AuditEvent}
        isPrivate
      />
      <Route
        exact
        name="Auditoria de Milestones"
        path="/auditMileston"
        component={AuditMileston}
        isPrivate
      />
      <Route
        exact
        name="Criar Sociedade"
        path="/societies/new"
        component={NewSociety}
        isPrivate
      />
      <Route
        exact
        name="Criar um Projeto"
        path="/newProject"
        component={NewProject}
        isPrivate
      />
      <Route
        exact
        name="Sociedade"
        path="/societies"
        component={Societies}
        isPrivate
      />
      <Route
        exact
        name="Editar Sociedade"
        path="/editSocieties"
        component={EditSocieties}
        isPrivate
      />
      <Route
        exact
        name="Snapshot"
        path="/snapshot"
        component={Snapshot}
        isPrivate
      />
      <Route
        exact
        name="Adicionar Evento"
        path="/addEvent"
        component={AddEvent}
        isPrivate
      />
      <Route
        exact
        name="Detalhes do projeto"
        path="/projectDetail"
        component={ProjectDetail}
        isPrivate
      />
      <Route
        exact
        name="Criar Tarefa"
        path="/newTask"
        component={NewTask}
        isPrivate
      />
      <Route
        exact
        name="Criar Documento"
        path="/newDocument"
        component={NewDocument}
        isPrivate
      />
      <Route
        exact
        name="Detalhes da Tarefa"
        path="/tasks/:id"
        component={TaskDetail}
        isPrivate
      />
      <Route
        exact
        name="Detalhes do Documento"
        path="/documents/:id"
        component={DocDetail}
        isPrivate
      />
      <Route
        exact
        name="Criar Milestone"
        path="/newMilestone"
        component={NewMilestone}
        isPrivate
      />
      <Route
        exact
        name="Detalhes do Milestone"
        path="/milestones/:id"
        component={MilestoneDetail}
        isPrivate
      />
      <Route
        name="Padrão"
        path="*"
        to='/'
      />
    </Switch>
  );
}
