import React from 'react';
import { useDispatch } from 'react-redux';
import { Form } from '@rocketseat/unform';
import { Link } from 'react-router-dom';

import { ForgotPasswordValidation } from '~/validations';

import logo from '~/assets/img/figo.png';

import Button from '~/components/Button';
import Input from '~/components/Input';
import Animated from '~/components/Animated';

// import * as ForgotPasswordActions from '~/store/modules/forgotPassword/actions';

import { Container, Logo } from './styles';

export default function ForgotPassword() {
  const dispatch = useDispatch();

  function handleSubmit({ email }) {
    // dispatch(ForgotPasswordActions.request(email));
  }

  return (
    <Animated name="fadeIn" delay={200}>
      <Container>
        <Logo src={logo} alt="" />
        <Form schema={ForgotPasswordValidation} onSubmit={handleSubmit}>
          <p>
            Digite o endereço de e-mail da sua conta e nós lhe enviaremos um
            link onde você poderá redefenir sua senha.
          </p>
          <Input
            type="email"
            name="email"
            placeholder="e-mail"
            placeholderAlign="center"
            autoCapitalize="off"
            width={350}
            widthType="px"
          />
          <Button type="submit" background="var(--secondary)" color="#fff">
            enviar
          </Button>
        </Form>
        <Link to="/">Voltar!</Link>
      </Container>
    </Animated>
  );
}
