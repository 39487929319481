import React from 'react';

import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Container } from './styles';
import TableParticipantes from '~/components/TableParticipantes';
import { start, stop } from '~/store/modules/loader/actions';
import api from '~/services/api';
import { store } from '~/store';
import { requestProjectDetails } from '~/store/modules/project/actions';

function Participants({ project }) {
  const dispatch = useDispatch();
  const { token } = store.getState().AuthReducer;
  const { participants } = useSelector(state => state.ProjectReducer.project);
  const { projectId } = useSelector(state => state.SocietyReducer);

  function createData(name, email, manager, id) {
    return { name, email, manager, id };
  }

  const rows =
  participants.map(p =>
      createData(p.name, p.email, p.isManager, p.id)
    ) ?? [];

  async function createParticipant(params) {
    dispatch(start());
    try {
      await api(token).post(`/api/me/projects/${projectId}/participants`, params, {
        method: 'POST'
      });
      dispatch(requestProjectDetails(token, projectId));
      toast.success('Participante criado com sucesso!');
    } catch (e) {
      toast.error('Aconteceu algum problema, tente novamente!');
    } finally {
      dispatch(stop());
    }
  }

  async function submitDelete(params) {
    dispatch(start());
    try {
      await api(token).delete(`/api/me/projects/${projectId}/participants/${params}`, {
        method: 'DELETE'
      });
      dispatch(requestProjectDetails(token, projectId));
      toast.success('Participante deletada com sucesso!');
    } catch (e) {
      toast.error('Aconteceu algum problema, tente novamente!');
      return false
    } finally {
      dispatch(stop());
    }

    return true
  }

  async function deleteParticipant(params) {
    dispatch(start());
    console.log(params)
    params.map(i => {
      if (submitDelete(i.id)) {
        if (i !== -1) {
          params.splice(i, 1)
        }
      }
    });
    dispatch(requestProjectDetails(token, projectId));
    dispatch(stop());
  }

  return (
    <>
      <Container>
        <TableParticipantes
          rows={rows}
          project={project}
          createParticipant={createParticipant}
          deleteParticipant={deleteParticipant}
        />
      </Container>
    </>
  );
}

export default Participants;
Participants.propTypes = {
  project: PropTypes.shape().isRequired,
};
