import { produce } from 'immer';

import DashboardTypes from './types';

const INITIAL_STATE = {
  numbers: {
    allCars: 0,
    ownCars: 0,
    thirdCars: 0,
  },
};

export default function reducer(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case DashboardTypes.GET_NUMBERS_CARS_SUCCESS:
        draft.numbers = action.payload.numbers;
        break;
      default:
    }
  });
}
