import React from 'react';

import { Container } from './styles';

import EnhancedTableHead from '../../components/TableProject';
import Animated from '~/components/Animated';

function AuditProject() {
  function createData(nome, email, ip, data, tipo, idaud) {
    return { nome, email, ip, data, tipo, idaud };
  }

  const rows = [
    createData(
      'Cupcake',
      'igorambonatti@teste.com',
      '189.4.100.300',
      67,
      'igor',
      12
    ),
    createData('Donut', '14/02/16', 'Conta interna', 51, 'mateus', 12),
    createData('Eclair', '14/02/12', 'Conta interna', 24, 'abel', 12),
    createData(
      'Frozen yoghurt',
      '14/02/19',
      'Conta Google',
      24,
      'catarina',
      12
    ),
    createData('Gingerbread', '14/02/11', 'Conta Google', 49, 'josé', 12),
    createData('Honeycomb', '14/02/21', 'Conta Google', 87, 'josé', 12),
    createData(
      'Ice cream sandwich',
      '14/02/12',
      'Conta Google',
      37,
      'josé',
      12
    ),
  ];
  return (
    <Animated name="fadeIn" delay={300}>
      <Container>
        <EnhancedTableHead rows={rows} />
      </Container>
    </Animated>
  );
}

export default AuditProject;
