import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  width: 95%;
  margin: auto;
  margin-top: 50px;
  margin-bottom: 70px;
  // box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.1);
  @media (max-width: 900px) {
    width: 95%;
  }
  .MuiCheckbox-colorSecondary.Mui-checked {
    color: var(--secondary);
  }
`;
