import React, { useState } from 'react';

import { Form } from '@rocketseat/unform';
import { useSelector, useDispatch } from 'react-redux';
import Select from 'react-select';
import { toast } from 'react-toastify';
import Animated from '~/components/Animated';
import { Container, Content } from './styles';
import history from '~/services/history';
import Input from '~/components/Input';
import { NewDocumentValidation } from '~/validations';
import { start, stop } from '~/store/modules/loader/actions';
import api from '~/services/api';
import { store } from '~/store';
import { requestProjectDetails } from '~/store/modules/project/actions';
import {Button} from "@material-ui/core";
import TitleBar from "~/components/TitleBar";
import { InputContainer } from '../../Tarefa/NewTask/styles';

function NewDocument() {
  const dispatch = useDispatch();
  const { token } = store.getState().AuthReducer;
  const { projectId } = useSelector(state => state.SocietyReducer);
  const { parties } = useSelector(state => state.ProjectReducer.project ?? []);
  const [selectedParties, setSelectedParties] = useState([]);
  const [partiesOptions, setPartiesOptions] = useState(parties.map(part => {
    return {
      label: part.person.name,
      value: part.person.id
    }
  }) ?? []);

  async function createDocument(params) {
    dispatch(start());
    try {
      await api(token).post(`/api/me/projects/${projectId}/documents`, params);
      dispatch(requestProjectDetails(token, projectId));
      toast.success('Documento criado!');
      history.go(-1)
    } catch (e) {
      toast.error('Aconteceu algum problema, tente novamente!');
    } finally {
      dispatch(stop());
    }
  }

  async function submitForm(data) {
    const dataFormated = {
      id: data?.numberDocument,
      title: data?.title,
      number: data?.numberDocument,
      externalLink: data?.doc,
      externalLinkPdf: data?.pdf,
      idPartiesSelected: selectedParties.map(item => item.value) ?? [],
    };

    createDocument(dataFormated);
    return false;
  }

  function handleSelectParties(data) {
    setSelectedParties(data);
  }

  return (
    <>
      <Animated name="fadeIn" delay={200}>
        <Form onSubmit={submitForm} schema={NewDocumentValidation}>
          <TitleBar
            title="Novo Documento"
            canBack
            actions={
              <>
                <Button
                  type="submit"
                  variant="contained"
                  disableElevation
                  color="secondary"
                  style={{ flex: '0 0 auto' }}
                >
                  Salvar
                </Button>

                <Button
                  variant="outlined"
                  color="secondary"
                  style={{ flex: '0 0 auto' }}
                  onClick={() => history.goBack(-1)}
                >
                  Cancelar
                </Button>
              </>
            }
          />

          <Content>
            <Input 
              label="Título" 
              name="title"
            />

            <Input
              label="Número do documento"
              name="numberDocument"
            />

            <Input
              label="Link Externo (Versão DOC)"
              type="url"
              name="doc"
            />

            <Input
              label="Link Externo (Versão PDF)"
              type="url"
              name="pdf"
            />

            <InputContainer>
              <label><b>Partes</b></label>
              <Select
                name="parties"
                color="secondary"
                options={partiesOptions}
                placeholder="Selecione as Partes"
                value={selectedParties}
                onChange={handleSelectParties}
                isSearchable
                isMulti
              />
            </InputContainer>
          </Content>
        </Form>
      </Animated>
    </>
  );
}

export default NewDocument;
