import React, { useEffect, useState } from 'react';

import { Container, Content } from './styles';
import Animated from '~/components/Animated';
import history from '~/services/history';
import { store } from '~/store';
import { useSelector } from 'react-redux';
import api from '~/services/api';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Grid, Typography } from '@material-ui/core';
import TitleBar from '~/components/TitleBar';
import moment from 'moment/moment';

function MilestoneDetail() {
  const { token } = store.getState().AuthReducer;
  const { project } = store.getState().ProjectReducer;
  const [milestone, setMilestone] = useState({})
  const { id } = useParams()

  useEffect(() => {
    const fetchMilestones = async () => {
      return await api(token).get(`/api/me/projects/${project.id}/milestones`);
    };

    fetchMilestones().then(response => {
      const milestones = response.data;
      const milestone = milestones.find(milestone => milestone.id === id)
      if (!milestone) {
        history.go(-1)
      }

      setMilestone(milestone)
    });
  }, [token]);

  function castDate(dateObj){
    if (!dateObj) {
      return ''
    }

    return new Date(dateObj.join('/')).toLocaleDateString('pt-BR')    
  }

  return (
    <>
      <Animated name="fadeIn" delay={200}>
        <TitleBar
          title={`Milestone: ${milestone.name}`}
          subtitle={project.name}
          canBack
        />

        <Container>
          <Content>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <p><strong>Data de Conclusão:</strong></p>
                <p>{castDate(milestone.completionDate)}</p>
              </Grid>
              <Grid item xs={4}>
                <p><strong>Descrição:</strong></p>
                <p>{milestone.longDescription}</p>
              </Grid>
              <Grid item xs={4}>
                <p><strong>Observações:</strong></p>
                <p>{milestone.comments}</p>
              </Grid>
            </Grid>
          </Content>
        </Container>
      </Animated>
    </>
  );
}

export default MilestoneDetail;
