import React from 'react';

import { Container } from './styles';

import EnhancedTableHead from '../../components/TableLogins';
import Animated from '~/components/Animated';

function HistoryLogin() {
  function createData(nome, data, tipo) {
    return { nome, data, tipo };
  }

  const rows = [
    createData('Cupcake', '13/02/15', 'Conta interna', 67, 'igor'),
    createData('Donut', '14/02/16', 'Conta interna', 51, 'mateus'),
    createData('Eclair', '14/02/12', 'Conta interna', 24, 'abel'),
    createData('Frozen yoghurt', '14/02/19', 'Conta Google', 24, 'catarina'),
    createData('Gingerbread', '14/02/11', 'Conta Google', 49, 'josé'),
    createData('Honeycomb', '14/02/21', 'Conta Google', 87, 'josé'),
    createData('Ice cream sandwich', '14/02/12', 'Conta Google', 37, 'josé'),
  ];
  return (
    <Animated name="fadeIn" delay={300}>
      <Container>
        <EnhancedTableHead rows={rows} />
      </Container>
    </Animated>
  );
}

export default HistoryLogin;
