import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import history from '~/services/history';

import Paper from '@material-ui/core/Paper';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

import DeleteIcon from '@material-ui/icons/Delete';
import { Button } from '@material-ui/core';
import classNames from 'classnames';
import { IoIosFlag, IoIosTrash } from 'react-icons/io';
import { BsFlag } from 'react-icons/bs';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: 'titulo',
    numeric: false,
    disablePadding: true,
    label: 'Título',
  },
  {
    id: 'data',
    numeric: false,
    disablePadding: true,
    label: 'Data',
  },
  {
    id: 'link',
    numeric: false,
    disablePadding: true,
    label: '',
  },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{ color: 'var(--secondary)', fontWeight: 'bold' }}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  selected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    padding: 0,
    justifyContent: 'space-between',
  },
  button: {
    textTransform: 'none',
    fontSize: '15px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  filter: {
    color: 'var(--secondary)',
    textDecoration: 'none',
    textTransform: 'capitalize',
    padding: '0.25rem 0',
    transition: 'all 0.5s',
    borderRadius: '100px',
    backgroundColor: 'transparent',
    '&:hover, &:focus': {
      color: '#5290ea',
    },
  },
  activeFilter: {
    fontWeight: 'bold',
    padding: '0.25rem 0.5rem',
    backgroundColor: 'rgba(83,63,76,.1)',
    transition: 'all 0.5s',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { title } = props;
  const { selected } = props;
  const { deleteMilestone } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: selected.length > 0,
      })}>
      <Typography
        className={classes.title}
        variant="h6"
        id="tableTitle"
        component="div">
        <span>
          <b>{title}</b>
        </span>
      </Typography>

      <div className={classes.actions}>
        <Button
          color="primary"
          startIcon={<BsFlag />}
          className={classes.button}
          variant="text"
          onClick={() => {
            history.push('/newDocument');
          }}>
          Novo Milestone
        </Button>
      </div>
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  selected: PropTypes.number.isRequired,
};
EnhancedTableToolbar.defaultProps = {
  title: 'Milestones',
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    margin: 0,
  },
  table: {
    margin: 0,
    minWidth: 100,
    borderCollapse: 'separate',
  },
  row: {
    padding: '10px 14px',
    height: '50px',
    border: 'none',
    borderRadius: '10px',
  },
  oddRow: {
    backgroundColor: 'rgb(250,247,250)',
    '&:hover, &:focus': {
      backgroundColor: '#ffffff',
    },
  },
  cell: {
    border: 'none',
    fontSize: '14px',
    '&:first-child': {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
    },
    '&:last-child': {
      borderTopRightRadius: '10px',
      borderBottomRightRadius: '10px',
    },
  },
  visuallyHidden: {
    margin: 0,
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  title: {
    fontWeight: 'bold',
    fontSize: '17px',
  },
}));

export default function EnhancedTable(data) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { deleteMilestone } = data;

  const { rows } = data;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name => selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={dense ? 'small' : 'medium'}
          aria-label="enhanced table">
          <EnhancedTableHead
            classes={classes}
            selected={selected}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows.length}
            deleteMilestone={deleteMilestone}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.nome}
                    selected={isItemSelected}
                    className={classNames(
                      classes.row,
                      index % 2 === 0 ? classes.oddRow : undefined
                    )}>
                    <TableCell className={classes.cell} padding="checkbox" />
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      className={classes.cell}>
                      {row.titulo}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.cell}>
                      {row.data}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.cell}>
                      {row.link}
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
