import * as Yup from 'yup';

const validation = Yup.object({
  name: Yup.string().required('O NOME é obrigatório'),
  cnpj: Yup.string().required('O CNPJ é obrigatória'),
  cep: Yup.string().required('O CEP é obrigatória'),
  logradouro: Yup.string().required('O LOGRADOURO é obrigatória'),
  numero: Yup.string().required('O NUMERO é obrigatória'),
  bairro: Yup.string().required('O BAIRRO é obrigatória'),
  cidade: Yup.string().required('A CIDADE é obrigatória'),
  estado: Yup.string().required('O ESTADO é obrigatória'),
  complemento: Yup.string(),
});

export default validation;
