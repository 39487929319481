import styled from 'styled-components';
import NumberFormat from 'react-number-format';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 1 auto;
`;

export const Label = styled.span`
  margin-bottom: 5px;
  font-weight: 600;
`;

export const Input = styled(NumberFormat)`
  border: 1px solid rgba(83,63,76, .5);
  border-radius: 5px;
  color: #3b3c40;
  width: ${props => `${props.width}${props.widthtype}`};
  padding: 0.5rem 1rem;
  text-align: ${props => props.align};
  transition: all 0.2s ease;

  &:disabled {
    background-color: var(--secondary);
    user-select: none;
    pointer-events: none;
  }

  &:focus {
    color: var(--secondary);
  }

  &::placeholder {
    color: #cccccc;
  }

  & + span {
    align-self: flex-start;
    color: #fb5d62;
    font-size: 10px;
    font-weight: bold;
    margin: 0 5px 10px;
  }
`;
