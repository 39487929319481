import { put, call, all, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import * as Loader from '~/store/modules/loader/actions';
import ResetPasswordService from '~/services/resetPassword';
import history from '~/services/history';

import ResetPasswordTypes from './types';

export function* request({ payload }) {
  try {
    yield put(Loader.start());

    const { token, password } = payload;

    yield call(ResetPasswordService.store, token, password);

    history.push('/');

    toast.success('Nova senha cadastrada com sucesso!');
  } catch (e) {
    toast.error('Aconteceu algum erro inesperado, tente novamente mais tarde');
  } finally {
    yield put(Loader.stop());
  }
}

export default all([takeLatest(ResetPasswordTypes.REQUEST, request)]);
