import React, { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { Router } from 'react-router-dom';

import './dependencies';

import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import Routes from '~/routes';
import GlobalStyle from '~/styles/global';
import { store, persistor } from './store';
import Loader from '~/components/Loader';

import history from '~/services/history';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#533f4c',
    },
  },
});

function App() {
  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Router history={history}>
            <GlobalStyle />
            <ToastContainer autoClose={3000} />
            <Loader />
            <Routes />
          </Router>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  );
}

export default App;
