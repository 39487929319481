import SocietyTypes from './types';

const INITIAL_STATE = {
  societyId: null,
  projectId: null,
};

export const SocietyReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SocietyTypes.SELECT_SOCIETY_TOKEN:
      return {
        ...state,
        societyId: action.payload.token,
      };
    case SocietyTypes.SELECT_PROJECT_TOKEN:
      return {
        ...state,
        projectId: action.payload.token,
      };
    default:
      return state;
  }
};
