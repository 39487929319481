/* eslint-disable react/button-has-type */
import React, { useState } from 'react';

import { Container, CardButton } from './styles';
import Animated from '~/components/Animated';
import Emissao from './Emissao';
import Transferencia from './Transferencia';
import Gravame from './Gravame';
import Reducao from './Reducao';
import Integralizacao from './Integralizacao';
import history from '~/services/history';

function AddEvent() {
  const [step, setStep] = useState(0);
  const [selected, setSelected] = useState(0);

  function getStepContent(mainStep) {
    switch (mainStep) {
      case 0:
        return <Emissao />;
      case 1:
        return <Transferencia />;
      case 2:
        return <Reducao />;
      case 3:
        return <Gravame />;
      case 4:
        return <Integralizacao />;
      default:
        return 'Unknown step';
    }
  }
  const teste = [
    {
      text: 'EMISSÃO',
    },
    {
      text: 'TRANSFERÊNCIA',
    },
    {
      text: 'REDUÇÃO',
    },
    {
      text: 'GRAVAME',
    },
    {
      text: 'INTEGRALIZAÇÃO',
    },
  ];

  return (
    <>
      <Animated name="fadeIn" delay={200}>
        <Container>
          <div className="buttonsHead">
            {teste.map((i, index) => {
              return (
                <CardButton
                  color={index === selected}
                  onClick={() => {
                    setStep(index);
                    setSelected(index);
                  }}
                >
                  {i.text}
                </CardButton>
              );
            })}
          </div>
          <div>{getStepContent(step)}</div>
          <button
            type="button"
            className="back"
            onClick={() => {
              history.push('/societies');
            }}
          >
            resumo
          </button>
        </Container>
      </Animated>
    </>
  );
}

export default AddEvent;
