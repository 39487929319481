import styled from 'styled-components';

export const Container = styled.div`
  background: #fff;
  padding: 25px;
  box-shadow: 0px 5px 10px 1px rgba(0, 0, 0, 0.25);
  form {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px 0;
    max-width: 400px;

    p {
      color: var(--secondary);
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      margin-bottom: 20px;
      width: 100%;
    }

    button {
      margin: 10px 0 10px;
      width: 200px;
    }
  }

  a {
    color: var(--secondary);
    font-size: 18px;
    transition: all 0.2s ease-in;

    &:hover {
      color: var(--secondary);
    }
  }
`;

export const Logo = styled.img`
  width: 300px;
  margin-bottom: 40px;
`;
