import React from 'react';

import { Container } from './styles';

import EnhancedTableHead from '../../components/TableDocs';
import Animated from '~/components/Animated';

function AuditDocs() {
  function createData(numero, titulo, usuario, ip, data, tipo, idaud) {
    return { numero, titulo, usuario, ip, data, tipo, idaud };
  }

  const rows = [
    createData('Cupcake', 305, 3.7, 67, 'igor', '23', 321),
    createData('Donut', 452, 25.0, 51, 'mateus', '23', 321),
    createData('Eclair', 262, 16.0, 24, 'abel', '23', 321),
    createData('Frozen yoghurt', 159, 6.0, 24, 'catarina', '23', 321),
    createData('Gingerbread', 356, 16.0, 49, 'josé', '23', 321),
    createData('Honeycomb', 408, 3.2, 87, 'josé', '23', 321),
    createData('Ice cream sandwich', 237, 9.0, 37, 'josé', '23', 321),
  ];
  return (
    <Animated name="fadeIn" delay={300}>
      <Container>
        <EnhancedTableHead rows={rows} />
      </Container>
    </Animated>
  );
}

export default AuditDocs;
