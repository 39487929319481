import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-date-picker';

import { useField } from '@rocketseat/unform';

import { Container, Label } from './styles';

export default function DatePicker({
  name,
  label,
  width,
  widthType,
  disabled,
}) {
  const ref = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const [selected, setSelected] = useState(defaultValue);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: ref.current,
      path: 'props.selected',
      clearValue: pickerRef => {
        pickerRef.clear();
      },
    });
  }, [ref.current, fieldName]); // eslint-disable-line

  return (
    <Container width={width} widthtype={widthType} disabled={disabled}>
      {label && <Label>{label}</Label>}
      <ReactDatePicker
        ref={ref}
        calendarIcon={null}
        clearIcon={null}
        dayPlaceholder=""
        name={fieldName}
        selected={selected}
        onChange={date => setSelected(date)}
        value={selected}
        disabled={disabled}
        className="datepicker-custom"
        calendarClassName="calendar-custom"
      />
      {error && <span>{error}</span>}
    </Container>
  );
}

DatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  width: PropTypes.number,
  widthType: PropTypes.string,
  disabled: PropTypes.bool,
};

DatePicker.defaultProps = {
  label: '',
  width: 100,
  widthType: '%',
  disabled: false,
};
