import React, { useState } from 'react';

import { Container } from './styles';

import EnhancedTableHead from '../../components/TableUsers';
import Animated from '~/components/Animated';

function Users() {
  const [show, setShow] = useState(false);

  function createData(name, calories, fat, carbs, email) {
    return { name, calories, fat, carbs, email };
  }

  const rows = [
    createData('Cupcake', 305, 3.7, 67, 'igor'),
    createData('Donut', 452, 25.0, 51, 'mateus'),
    createData('Eclair', 262, 16.0, 24, 'abel'),
    createData('Frozen yoghurt', 159, 6.0, 24, 'catarina'),
    createData('Gingerbread', 356, 16.0, 49, 'josé'),
    createData('Honeycomb', 408, 3.2, 87, 'josé'),
    createData('Ice cream sandwich', 237, 9.0, 37, 'josé'),
  ];
  return (
    <Animated name="fadeIn" delay={300}>
      <Container>
        <EnhancedTableHead rows={rows} />
      </Container>
    </Animated>
  );
}

export default Users;
