import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  addButton: {
    backgroundColor: '#533f4c',
    color: 'white',
    display: 'block',
    padding: '0.5rem 1rem',
    textAlign: 'center',
    borderRadius: '5px',
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: '14px',
    transition: 'all 0.3s',
    '&:hover, &:focus': {
      opacity: 0.9,
      backgroundColor: '#533f4c'
    },
  },
}));

export default function AddButton({ text = 'Adicionar', onClick }) {
  const classes = useStyles();
  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Button onClick={onClick} className={classes.addButton}>{text}</Button>
  );
}

AddButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};
