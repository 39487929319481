import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Checkbox from '@material-ui/core/Checkbox';
import classNames from 'classnames';
import { toast } from 'react-toastify';
import { Button } from '@material-ui/core';
import { IoIosPerson, IoIosPersonAdd, IoIosTrash } from 'react-icons/io';
import ModalInfo from '~/components/ModalInfo';
import Input from '~/components/Input';
import InputFormat from '~/components/NumberFormatInput';
import { store } from '~/store';
import ConfirmationDialog from '../ConfirmationDialog';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  array = array || [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  {
    id: 'name',
    numeric: false,
    disablePadding: true,
    label: 'Nome',
  },
  {
    id: 'cpf',
    numeric: false,
    disablePadding: true,
    label: 'CPF',
  },
  {
    id: 'tipo',
    numeric: false,
    disablePadding: true,
    label: 'Tipo',
  },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
          }
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}>
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              style={{ color: 'var(--secondary)', fontWeight: 'bold' }}>
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    padding: 0,
    justifyContent: 'space-between',
    minHeight: 'unset',
    marginBottom: '1rem',
  },
  button: {
    textTransform: 'none',
    fontSize: '15px',
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  filter: {
    color: 'var(--secondary)',
    textDecoration: 'none',
    textTransform: 'capitalize',
    padding: '0.25rem 0',
    transition: 'all 0.5s',
    borderRadius: '100px',
    backgroundColor: 'transparent',
    '&:hover, &:focus': {
      color: '#5290ea',
    },
  },
  activeFilter: {
    fontWeight: 'bold',
    padding: '0.25rem 0.5rem',
    backgroundColor: 'rgba(83,63,76,.1)',
    transition: 'all 0.5s',
  },
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const [show, setShow] = useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [type, setType] = useState('Fisica');
  const [cpf, setCpf] = useState();
  const { projectId } = store.getState().SocietyReducer;
  const { numSelected } = props;
  const { title } = props;
  const { deleteParties } = props;
  const { createProject } = props;
  const { selectedItems } = props;
  const error = () => {
    toast.error('Complete todos os dados!');
  };

  const submit = () => {
    if (name && cpf && type && email) {
      createProject({
        name,
        identification: cpf,
        type: type === 'Fisica' ? 'PHYSICAL' : 'LEGAL',
        email,
        projectId,
        id: name,
      });
      setShow(false);
    } else {
      error();
    }
  };
  return (
    <>
      <ConfirmationDialog
        title="Deseja realmente excluir esta Parte?"
        visible={showRemoveDialog && selectedItems.length > 0}
        onConfirm={() => deleteParties(selectedItems)}
        onCancel={() => setShowRemoveDialog(false)}
      />

      <ModalInfo
        name="modal-confirm"
        width={50}
        show={show}
        cancel={() => {
          setShow(false);
        }}
        title="Cadastrar nova Parte">
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            gap: '1rem',
          }}>
          <Input
            label="Nome"
            name="nome"
            onChange={event => {
              setName(event.target.value);
            }}
          />

          <div
            style={{ display: 'flex', flexDirection: 'column', width: '50%' }}>
            <label style={{ textAlign: 'left', marginBottom: '5px' }}>
              Tipo
            </label>
            <select
              name="tipo"
              id="tipo"
              style={{
                background: 'white',
                border: '1px solid rgba(83,63,76, .5)',
                borderRadius: '5px',
                padding: '0.5rem 1rem',
                color: '#3b3c40',
                cursor: 'pointer',
                fontSize: '14px',
                width: '100%',
              }}
              onChange={e => {
                setType(e.target.value);
              }}>
              <option value="Fisica" style={{ color: '#000' }}>
                Pessoa Física
              </option>
              <option value="Juridica" style={{ color: '#000' }}>
                Pessoa Júridica
              </option>
            </select>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginTop: '2rem',
            gap: '1rem',
          }}>
          <InputFormat
            isFormattedValue
            name="cpf"
            format={type === 'Fisica' ? '###.###.###-##' : '##.###.###/####-##'}
            label="CPF | CNPJ"
            onChange={data => {
              setCpf(data.value);
            }}
          />

          <Input
            label="Email"
            name="email"
            onChange={event => {
              setEmail(event.target.value);
            }}
          />
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginTop: '2rem',
            alignItems: 'center',
            gap: '1rem',
          }}>
          <Button
            variant="contained"
            disableElevation
            color="secondary"
            style={{ flex: '1 1 auto' }}
            onClick={submit}>
            Cadastrar
          </Button>

          <Button
            variant="outlined"
            color="secondary"
            style={{ flex: '1 1 auto' }}
            onClick={() => {
              setShow(false);
            }}>
            Cancelar
          </Button>
        </div>
      </ModalInfo>
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}>
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div">
          <span>
            <b>{title}</b>
          </span>
        </Typography>

        <div className={classes.actions}>
          <Button
            color="primary"
            startIcon={<IoIosPersonAdd />}
            className={classes.button}
            variant="text"
            onClick={() => {
              setShow(true);
            }}>
            Nova Parte
          </Button>

          <Button
            startIcon={<IoIosTrash />}
            className={classes.button}
            disabled={numSelected === 0}
            variant="text"
            onClick={() => {
              setShowRemoveDialog(true);
            }}>
            Excluir
          </Button>
        </div>
      </Toolbar>
    </>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
  title: PropTypes.string,
  project: PropTypes.object.isRequired,
};
EnhancedTableToolbar.defaultProps = {
  title: 'Participantes',
};

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
    margin: 0,
  },
  table: {
    margin: 0,
    minWidth: 100,
    borderCollapse: 'separate',
  },
  row: {
    padding: '10px 14px',
    height: '50px',
    border: 'none',
    borderRadius: '10px',
  },
  oddRow: {
    backgroundColor: 'rgb(250,247,250)',
    '&:hover, &:focus': {
      backgroundColor: '#ffffff',
    },
  },
  cell: {
    border: 'none',
    fontSize: '14px',
    '&:first-child': {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
    },
    '&:last-child': {
      borderTopRightRadius: 'IoIosPerson10px',
      borderBottomRightRadius: '10px',
    },
  },
  visuallyHidden: {
    margin: 0,
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  title: {
    fontWeight: 'bold',
    fontSize: '17px',
  },
}));

export default function EnhancedTable(data) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const { rows } = data;
  const { onChangeParties } = data;
  const { deleteParties } = data;
  const { project } = data;
  const { createProject } = data;
  const { title } = data;

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };
  // useEffect(() => {
  //   if (onChangeParties) {
  //     onChangeParties(selected);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [selected]);
  useEffect(() => {
    setSelected([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteParties]);

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = rows.map(n => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = name =>
    selected.map(item => item.name).indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage -
    Math.min(rowsPerPage, (rows?.length || 0) - page * rowsPerPage);
  return (
    <div className={classes.root}>
      <EnhancedTableToolbar
        numSelected={selected.length}
        title={title}
        project={project}
        createProject={createProject}
        deleteParties={deleteParties}
        selectedItems={selected}
      />
      <TableContainer>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          aria-label="enhanced table">
          <EnhancedTableHead
            classes={classes}
            numSelected={selected.length}
            order={order}
            orderBy={orderBy}
            onSelectAllClick={handleSelectAllClick}
            onRequestSort={handleRequestSort}
            rowCount={rows?.length || []}
          />
          <TableBody>
            {stableSort(rows, getComparator(order, orderBy))
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => {
                const isItemSelected = isSelected(row.name);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={event => handleClick(event, row)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.index}
                    selected={isItemSelected}
                    className={classNames(
                      classes.row,
                      index % 2 === 0 ? classes.oddRow : undefined
                    )}>
                    <TableCell padding="checkbox" className={classes.cell}>
                      <Checkbox
                        checked={isItemSelected}
                        color="secondary"
                        inputProps={{ 'aria-labelledby': labelId }}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.cell}
                      padding="none">
                      <b>{row.name}</b>
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.cell}
                      padding="none">
                      {row.cpf}
                    </TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      className={classes.cell}
                      padding="none">
                      {row.tipo === 'PHYSICAL' ? 'física' : 'juridica'}
                    </TableCell>
                  </TableRow>
                );
              })}
            {emptyRows > 0 && (
              <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                <TableCell colSpan={6} />
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5]}
        component="div"
        count={rows?.length || []}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
}
