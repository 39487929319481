import { takeLatest, all, put } from 'redux-saga/effects';

import history from '~/services/history';
import api from '~/services/api';
import { setProjectDetails } from './actions';
import { selectProjectId } from '../society/actions';

import ProjectTypes from './types';

export function* getProjects({ payload }) {
  const { token, projectId } = payload;
  yield put(selectProjectId(projectId));
  try {
    const response = yield api(token).get(`/api/me/projects/${projectId}`);

    yield put(setProjectDetails(response.data));
    history.push('/projectDetail');
  } catch (e) {
    console.error(e);
  }
}

export default all([
  // takeLatest('persist/REHYDRATE', setToken),
  takeLatest(ProjectTypes.SELECT_REQUEST_DETAILS, getProjects),
]);
