import styled from 'styled-components';

export const Container = styled.div`
  animation-fill-mode: both;
  animation-name: ${props =>
    `${props.isVisible ? props.animationName : props.animationNameOut}`};
  animation-delay: ${props =>
    `${props.isVisible ? props.delay : props.delayOut}ms`};
  animation-duration: ${props =>
    `${props.isVisible ? props.duration : props.durationOut}ms`};
  display: ${props => (props.isVisible ? 'block' : 'none')};
`;
