import React from 'react';

import { Container } from './styles';

import EnhancedTableHead from '../../components/TableEvent';
import Animated from '~/components/Animated';

function AuditEvent() {
  function createData(
    nome,
    evento,
    conclusao,
    entrega,
    usuario,
    ip,
    data,
    tipo,
    idaud
  ) {
    return { nome, evento, conclusao, entrega, usuario, ip, data, tipo, idaud };
  }

  const rows = [
    createData(
      'Cupcake',
      305,
      3.7,
      67,
      'igor',
      'teste',
      '1,2,3',
      'edição',
      '232'
    ),
    createData(
      'Donut',
      452,
      25.0,
      51,
      'mateus',
      'teste',
      '1,2,3',
      'edição',
      '232'
    ),
    createData(
      'Eclair',
      262,
      16.0,
      24,
      'abel',
      'teste',
      '1,2,3',
      'edição',
      '232'
    ),
    createData(
      'Frozen yoghurt',
      159,
      6.0,
      24,
      'catarina',
      'teste',
      '1,2,3',
      'edição',
      '232'
    ),
    createData(
      'Gingerbread',
      356,
      16.0,
      49,
      'josé',
      'teste',
      '1,2,3',
      'edição',
      '232'
    ),
    createData(
      'Honeycomb',
      408,
      3.2,
      87,
      'josé',
      'teste',
      '1,2,3',
      'edição',
      '232'
    ),
    createData(
      'Ice cream sandwich',
      237,
      9.0,
      37,
      'josé',
      'teste',
      '1,2,3',
      'edição',
      '232'
    ),
  ];
  return (
    <Animated name="fadeIn" delay={300}>
      <Container>
        <EnhancedTableHead rows={rows} />
      </Container>
    </Animated>
  );
}

export default AuditEvent;
