import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  height: 100vh;

  .body {
    display: flex;
    position: relative;
    width: 100%;
    height: max-content;
  }
`;

export const Content = styled.div`
  position: relative;
  display: block;
  overflow: auto;
  width: 100%;
  height: calc(100vh - 3rem);
  max-height: calc(100vh - 3rem);
  padding-bottom: 3rem;
  z-index: 1;

  .container-button {
    display: flex;
    margin: 0;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 0;
    left: 0;
    bottom: 40px;
    z-index: 2;

    &.dynamic {
      bottom: 0px;

      .button-menu {
        &:hover {
          transform: translateY(-33px);
        }
      }
    }

    .button-menu {
      background: linear-gradient(0deg, #02b5b2 0%, #4acac8 100%);
      border: none;
      border-radius: 50%;
      position: relative;
      margin-top: 10px;
      padding: 10px;
      transition: all 0.5s ease-in-out;
    }
  }
`;

export const ImageBackground = styled.img`
  position: fixed;
  top: 60px;
  right: 3%;
  margin-left: 320px;
  max-width: 650px;
  opacity: 0.2;

  animation-duration: 700ms;
  animation-name: slideInRight;
  animation-fill-mode: both;
`;
