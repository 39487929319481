import React, { useEffect, useState } from 'react';

import { Container } from './styles';
import Animated from '~/components/Animated';
import TableTarefas from '~/components/TableTarefas';
import { useSelector } from 'react-redux';
import { store } from '~/store';
import api from '~/services/api';

function Tarefa() {
  const { token } = store.getState().AuthReducer;
  const { projectId } = useSelector(state => state.SocietyReducer);
  const [tasks, setTasks] = useState([])
  
  useEffect(() => {
    const fetchTasks = async () => {
      return await api(token).get(`/api/me/projects/${projectId}/tasks`);
    };

    fetchTasks().then(response => {
      setTasks(response.data);
    });
  }, [token]);

  function createData({id, name, expectedDate, completionDate}) {
    return { id, name, expectedDate, completionDate }
  }

  function castDate(dateObj){
    if (!dateObj) {
      return ''
    }

    return new Date(dateObj.join('/')).toLocaleDateString('pt-BR')    
  }

  const rows = tasks.map(task => createData({
    id: task.id,
    name: task.name,
    expectedDate: castDate(task.expectedDate),
    completionDate: task.completionDate ? castDate(task.completionDate) : null
  }))
  return (
    <Container>
      <TableTarefas rows={rows} />
    </Container>
  );
}

export default Tarefa;
