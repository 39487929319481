import { takeLatest, all, call, put } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import UserService from '~/services/user';

import { updateProfileSuccess, updateProfileFailure } from './actions';

import UserTypes from './types';

export function* updateProfile({ payload }) {
  try {
    const { name, email, ...rest } = payload.data;

    const profile = { name, email, ...(rest.oldPassword ? rest : {}) };

    const response = yield call(UserService.store, profile);

    yield put(updateProfileSuccess(response.data));

    toast.success('Perfil atualizado com sucesso!');
  } catch (e) {
    toast.error('Erro ao atualizar perfil, verfique seus dados!');
    yield put(updateProfileFailure());
  }
}

export default all([
  takeLatest(UserTypes.UPDATE_PROFILE_REQUEST, updateProfile),
]);
