import styled from 'styled-components';

export const Container = styled.div`
  width: 85%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  margin-top: 40px;
  .buttonsHead {
    margin: auto;
  }
  > button {
    margin-top: 20px;
    outline-style: none;
    border: none;
    background: none;
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    transition: all 0.2s linear;
    color: #fff;
    &:hover {
      transform: translateY(-5px);
    }
  }
`;
export const CardButton = styled.button`
  border-radius: 8px;
  border: none;
  background-color: ${props => (props.color ? 'var(--primary)' : '#fff')};
  color: ${props => (props.color ? '#fff' : 'var(--secondary)')};
  padding: 15px;
  font-size: 14px;
  font-weight: bold;
  width: 150px;
  margin: 10px 15px;
  transition: all 0.2s linear;
  &:hover {
    box-shadow: 2px 5px 15px 1px rgba(0, 0, 0, 0.2);
    transform: translateY(-2px);
  }
`;
