import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from '@material-ui/core';

import { Button } from './styles';

export default function ButtonCustom({
  type,
  width,
  height,
  background,
  color,
  isCircle,
  children,
  tooltip,
  tooltipPosition,
  className,
  ...rest
}) {
  const buttonProps = {
    type,
    width,
    height,
    isCircle,
    background,
    color,
    className,
    ...rest,
  };

  return tooltip ? (
    <Tooltip title={tooltip} placement={tooltipPosition}>
      <Button {...buttonProps}>{children}</Button>
    </Tooltip>
  ) : (
    <Button {...buttonProps}>{children}</Button>
  );
}

ButtonCustom.propTypes = {
  background: PropTypes.string,
  color: PropTypes.string,
  isCircle: PropTypes.bool,
  type: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  tooltip: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string])
    .isRequired,
  tooltipPosition: PropTypes.string,
};

ButtonCustom.defaultProps = {
  background: 'var(--secondary)',
  color: '#ffffff',
  isCircle: false,
  type: 'button',
  height: 40,
  width: 250,
  tooltip: '',
  className: '',
  tooltipPosition: 'bottom',
};
