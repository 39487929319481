import React from 'react';

import { Container } from './styles';

import EnhancedTableHead from '../../components/TableMilestones';
import Animated from '~/components/Animated';

function AuditMileston() {
  function createData(nome, usuario, ip, data, tipo, idaud) {
    return { nome, usuario, ip, data, tipo, idaud };
  }

  const rows = [
    createData('Cupcake', 305, 3.7, 67, 'igor', 322),
    createData('Donut', 452, 25.0, 51, 'mateus', 322),
    createData('Eclair', 262, 16.0, 24, 'abel', 322),
    createData('Frozen yoghurt', 159, 6.0, 24, 'catarina', 322),
    createData('Gingerbread', 356, 16.0, 49, 'josé', 322),
    createData('Honeycomb', 408, 3.2, 87, 'josé', 322),
    createData('Ice cream sandwich', 237, 9.0, 37, 'josé', 322),
  ];
  return (
    <Animated name="fadeIn" delay={300}>
      <Container>
        <EnhancedTableHead rows={rows} />
      </Container>
    </Animated>
  );
}

export default AuditMileston;
