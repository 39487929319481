import ProjectTypes from './types';

export function setProjectDetails(data) {
  return {
    type: ProjectTypes.SET_PROJECT_DETAILS,
    payload: data,
  };
}

export function requestProjectDetails(token, projectId) {
  return {
    type: ProjectTypes.SELECT_REQUEST_DETAILS,
    payload: { token, projectId },
  };
}

export function setDocumentDetails(data) {
  return {
    type: ProjectTypes.SET_PROJECT_DETAILS,
    payload: data,
  };
}

export function requestDocumentDetails(token, documentId) {
  return {
    type: ProjectTypes.SELECT_REQUEST_DETAILS,
    payload: { token, documentId },
  };
}
// export function selectProjectId(token) {
//   return {
//     type: SocietyTypes.SELECT_PROJECT_TOKEN,
//     payload: { token },
//   };
// }
