import React, { useState } from 'react';
import Animated from '~/components/Animated';
import DatePicker from '~/components/DatePicker';
import Stepper from '~/components/Stepper';
import history from '~/services/history';
import TableSubscritor from '~/components/TableSubscritor';
import TableClass from '~/components/TableClass';

import { Container, Button, ButtonBack, Section } from './styles';

function Gravame() {
  const [step, setStep] = useState(0);

  function createData(name) {
    return { name };
  }

  const rows = [
    createData('Cupcake'),
    createData('Donut'),
    createData('Eclair'),
    createData('Frozen yoghurt'),
    createData('Gingerbread'),
    createData('Honeycomb'),
    createData('Ice cream sandwich'),
  ];
  function Data() {
    return (
      <div className="data">
        <DatePicker name="data" label="Selecione a data" width={30} />
        <Section>
          <label>Tipo</label>
          <textarea
            name="tipo"
            id="tipo"
            rows="3"
            style={{ padding: '10px' }}
          />
        </Section>
      </div>
    );
  }
  function Partner() {
    return (
      <div className="body">
        <TableSubscritor rows={rows} />
        <TableClass rows={rows} />
      </div>
    );
  }
  function getStepContent(mainStep) {
    switch (mainStep) {
      case 0:
        return <Data />;
      case 1:
        return <Partner />;
      // case 2:
      //   return <Value />;
      // case 3:
      //   return <Resume />;
      default:
        return 'Unknown step';
    }
  }
  return (
    <>
      <Animated name="fadeIn" delay={200}>
        <Container>
          <Stepper stepMain={step} />
          <div className="dataInput">{getStepContent(step)}</div>
          <div className="buttons">
            <ButtonBack
              onClick={() => {
                if (step > 0) {
                  return setStep(step - 1);
                }
                return history.push('/societies');
              }}
            >
              VOLTAR
            </ButtonBack>
            <>
              {step === 3 ? (
                <Button
                  onClick={() => {
                    setStep(step + 1);
                  }}
                >
                  CONCLUIR
                </Button>
              ) : (
                <Button
                  onClick={() => {
                    setStep(step + 1);
                  }}
                >
                  AVANÇAR
                </Button>
              )}
            </>
          </div>
        </Container>
      </Animated>
    </>
  );
}

export default Gravame;
