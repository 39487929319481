import React from 'react';
import PropTypes from 'prop-types';

import { Container, Label, Input } from './styles';

export default function InputCustom({
  name,
  label,
  placeholder,
  type,
  width,
  widthType,
  placeholderAlign,
  autoCapitalize,
  autoFocus,
  disabled,
  onChange,
  ...rest
}) {
  return (
    <Container>
      {label && <Label>{label}</Label>}
      <Input
        autoCapitalize={autoCapitalize}
        autoComplete="off"
        autoFocus={autoFocus}
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        name={name}
        width={width}
        widthtype={widthType}
        align={placeholderAlign}
        onChange={onChange}
        {...rest}
      />
    </Container>
  );
}

InputCustom.propTypes = {
  name: PropTypes.string.isRequired,
  autoCapitalize: PropTypes.string,
  autoFocus: PropTypes.bool,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  placeholderAlign: PropTypes.string,
  type: PropTypes.string,
  width: PropTypes.number,
  widthType: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
};

InputCustom.defaultProps = {
  autoCapitalize: 'on',
  autoFocus: false,
  label: '',
  type: 'text',
  placeholder: '',
  placeholderAlign: 'left',
  width: 100,
  widthType: '%',
  disabled: false,
  onChange: () => {},
};
