import styled from 'styled-components';

export const Container = styled.div`
  z-index: 1;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 3rem;
  border-bottom: 1px solid rgba(220,217,220,.3);
  box-shadow:
    0px 0.7px 1.4px rgba(0, 0, 0, 0.012),
    0px 2.2px 4.7px rgba(0, 0, 0, 0.018),
    0px 10px 21px rgba(0, 0, 0, 0.03);

  .brand {
    width: fit-content;
    max-width: 100px;
  }
  
  .brand img {
    display: block;
    width: 100%;
  }

  .actions {
    display: flex;
    justify-content: flex-end;
  }
  .actions > span {
    margin-left: 1rem;
  }

  a {
    text-decoration: none;
    color: var(--primary);
  }
`;
